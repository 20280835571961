import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema.js'; // eslint-disable-line
import uiSchema from './uiSchema';

const Vehicle = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('accident'));
    const formData = localData && localData.vehicle ? localData.vehicle : {};

    const onSubmit = data => {
        const vehicle = data.formData;

        window.dataLayer.push({
            event: 'accidentClaimSubmission',
            step: 2,
            vehicle_make: vehicle.make,
            vehicle_model: vehicle.model,
            vehicle_year_built: vehicle.manufactureYear ? Number(vehicle.manufactureYear) : 0,
            vehicle_condition: vehicle.condition.hasDefects,
            vehicle_rego: vehicle.registration,
            vehicle_endorsements_required: vehicle.endorsementsRequired,
            vehicle_sole_owner: vehicle.owner.soleOwner,
            vehicle_finance_owed: vehicle.finance.financeOwing,
        });

        saveLocalData({ vehicle });
        saveContinue();
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    2. Insured Vehicle
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Vehicle.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Vehicle.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Vehicle;

export default {
    definitions: {
        vehicle: {
            type: 'object',
            required: [
                'ownerName',
                'driverName',
                'phone',
                'registration',
                'make',
                'model',
                'insurance',
            ],
            properties: {
                ownerName: {
                    type: 'string',
                },
                driverName: {
                    type: 'string',
                },
                phone: {
                    type: 'string',
                },
                registration: {
                    type: 'string',
                },
                make: {
                    type: 'string',
                },
                model: {
                    type: 'string',
                },
                insurance: {
                    type: 'string',
                },
            },
        },
    },
    type: 'object',
    required: ['details', 'vehicleLocation'],
    properties: {
        details: {
            type: 'string',
        },

        bestContact: {
            type: 'object',
            required: ['isPolicyHolder'],
            properties: {
                isPolicyHolder: {
                    type: 'boolean',
                },
            },
            dependencies: {
                isPolicyHolder: {
                    oneOf: [
                        {
                            properties: {
                                isPolicyHolder: { enum: [true] },
                            },
                        },
                        {
                            required: ['name', 'phone', 'address'],
                            properties: {
                                isPolicyHolder: { enum: [false] },

                                name: {
                                    type: 'string',
                                },
                                phone: {
                                    type: 'string',
                                },
                                address: {
                                    type: 'string',
                                },
                            },
                        },
                    ],
                },
            },
        },

        preferredRepairer: {
            type: 'object',
            properties: {
                hasPreferredRepairer: {
                    type: 'boolean',
                },
            },
            dependencies: {
                hasPreferredRepairer: {
                    oneOf: [
                        {
                            properties: {
                                hasPreferredRepairer: { enum: [false] },
                            },
                        },
                        {
                            properties: {
                                hasPreferredRepairer: { enum: [true] },

                                name: {
                                    type: 'string',
                                },
                                address: {
                                    type: 'string',
                                },
                                phone: {
                                    type: 'string',
                                },
                                quote: {
                                    type: 'object',
                                    properties: {
                                        obtainedQuote: {
                                            type: 'boolean',
                                        },
                                    },
                                    dependencies: {
                                        obtainedQuote: {
                                            oneOf: [
                                                {
                                                    properties: {
                                                        obtainedQuote: { enum: [false] },
                                                    },
                                                },
                                                {
                                                    properties: {
                                                        obtainedQuote: { enum: [true] },
                                                        quoteEstimate: {
                                                            type: 'string',
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                        },
                    ],
                },
            },
        },

        vehicleLocation: {
            type: 'string',
        },

        anotherVehicle: {
            type: 'object',
            required: ['anotherVehicleDamaged'],
            properties: {
                stepHeading: { type: 'null' },
                anotherVehicleDamaged: {
                    type: 'boolean',
                },
            },
            dependencies: {
                anotherVehicleDamaged: {
                    oneOf: [
                        {
                            properties: {
                                anotherVehicleDamaged: { enum: [false] },
                            },
                        },
                        {
                            properties: {
                                anotherVehicleDamaged: { enum: [true] },
                                vehicle: {
                                    type: 'object',
                                    required: [
                                        'ownerName',
                                        'driverName',
                                        'phone',
                                        'registration',
                                        'make',
                                        'model',
                                        'insurance',
                                    ],
                                    properties: {
                                        ownerName: {
                                            type: 'string',
                                        },
                                        driverName: {
                                            type: 'string',
                                        },
                                        phone: {
                                            type: 'string',
                                        },
                                        registration: {
                                            type: 'string',
                                        },
                                        make: {
                                            type: 'string',
                                        },
                                        model: {
                                            type: 'string',
                                        },
                                        insurance: {
                                            type: 'string',
                                        },
                                    },
                                },
                                additionalVehicles: {
                                    type: 'array',
                                    items: {
                                        $ref: '#/definitions/vehicle',
                                    },
                                },
                            },
                        },
                    ],
                },
            },
        },

        nonVehicle: {
            type: 'object',
            required: ['nonVehiclePropertyDamaged'],
            properties: {
                stepHeading: { type: 'null' },
                nonVehiclePropertyDamaged: {
                    type: 'boolean',
                },
            },
            dependencies: {
                nonVehiclePropertyDamaged: {
                    oneOf: [
                        {
                            properties: {
                                nonVehiclePropertyDamaged: { enum: [false] },
                            },
                        },
                        {
                            required: ['nonVehiclePropertyDamageDetails'],
                            properties: {
                                nonVehiclePropertyDamaged: { enum: [true] },
                                nonVehiclePropertyDamageDetails: {
                                    type: 'string',
                                },
                            },
                        },
                    ],
                },
            },
        },

        injuries: {
            type: 'object',
            required: ['anyInjuries'],
            properties: {
                stepHeading: { type: 'null' },
                anyInjuries: {
                    type: 'boolean',
                },
            },
            dependencies: {
                anyInjuries: {
                    oneOf: [
                        {
                            properties: {
                                anyInjuries: { enum: [false] },
                            },
                        },
                        {
                            required: ['names', 'phones', 'hospital', 'emergencyServices'],
                            properties: {
                                anyInjuries: { enum: [true] },

                                names: {
                                    type: 'string',
                                },
                                phones: {
                                    type: 'string',
                                },
                                emergencyServices: {
                                    type: 'object',
                                    required: ['emergencyServicesAttended'],
                                    properties: {
                                        emergencyServicesAttended: {
                                            type: 'boolean',
                                        },
                                    },
                                    dependencies: {
                                        emergencyServicesAttended: {
                                            oneOf: [
                                                {
                                                    properties: {
                                                        emergencyServicesAttended: {
                                                            enum: [false],
                                                        },
                                                    },
                                                },
                                                {
                                                    required: ['emergencyServicesDetails'],
                                                    properties: {
                                                        emergencyServicesAttended: { enum: [true] },
                                                        emergencyServicesDetails: {
                                                            type: 'string',
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                hospital: {
                                    type: 'boolean',
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
};

export default {
    type: 'object',
    required: ['fullName', 'email', 'phone'],
    properties: {
        fullName: {
            type: 'string',
        },
        email: {
            type: 'string',
            format: 'email',
        },
        phone: {
            type: 'string',
            format: 'phone',
        },
        clientReference: {
            type: 'string',
        },
        addressLine1: {
            type: 'string',
        },
        addressLine2: {
            type: 'string',
        },
        suburb: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        postCode: {
            type: 'integer',
        },
    },
};

import React from 'react';

export default {
    anyAccidentsOrLosses: {
        anyAccidentsOrLossesRequired: {
            classNames: 'field-parent',
            'ui:title': (
                <span>
                    <b>01.</b> Had any accidents or losses in the last 5 years?*
                </span>
            ),
            'ui:widget': 'radio',
        },
        anyAccidentsOrLosses_details: {
            'ui:title': 'Please provide full details*',
        },
    },
    specialConditions: {
        specialConditionsRequired: {
            classNames: 'field-parent',
            'ui:title': (
                <span>
                    <b>02.</b> Had any special conditions imposed on a motor policy?*
                </span>
            ),
            'ui:widget': 'radio',
        },
        specialConditions_details: {
            'ui:title': 'Please provide full details*',
        },
    },
    licenceEndorsement: {
        licenceEndorsementRequired: {
            classNames: 'field-parent',
            'ui:title': (
                <span>
                    <b>03.</b> Had a driver licence endorsed, cancelled or any special conditions
                    imposed?
                </span>
            ),
            'ui:widget': 'radio',
        },
        licenceEndorsement_details: {
            'ui:title': 'Please provide full details*',
        },
    },
    convictionsOrFines: {
        convictionsOrFinesRequired: {
            classNames: 'field-parent',
            'ui:title': (
                <span>
                    <b>04.</b> Been convicted of or fined for a motoring offence (or have any
                    pending) in the last 7 years? Please include speeding offences, but you may
                    ignore parking offences imposed
                </span>
            ),
            'ui:widget': 'radio',
        },
        convictionsOrFines_details: {
            'ui:title': 'Please provide full details including dates and driver details*',
        },
    },
    insuranceDeclined: {
        insuranceDeclinedRequired: {
            classNames: 'field-parent',
            'ui:title': (
                <span>
                    <b>05.</b> Had any insurance declined, cancelled, avoided, renewal refused,
                    terms imposed or claim declined?
                </span>
            ),
            'ui:widget': 'radio',
        },
        insuranceDeclined_details: {
            'ui:title': 'Please provide full details, including dates:*',
        },
    },
    criminalActivity: {
        criminalActivityRequired: {
            classNames: 'field-parent',
            'ui:title': (
                <span>
                    <b>06.</b> Engaged in any criminal activity or had any criminal convictions,
                    acquittals or diversions, or have any criminal prosecutions pending? The
                    information sought by this question is subject to the rights set out in the
                    Criminal Records (Clean Slate) Act, 2004.
                </span>
            ),
            'ui:widget': 'radio',
        },
        criminalActivity_details: {
            'ui:title': 'Please provide full details*',
        },
    },
    anyFurtherInformation: {
        anyFurtherInformationRequired: {
            classNames: 'field-parent',
            'ui:title': (
                <span>
                    <b>07.</b> Is there any further information likely to affect this insurance?
                </span>
            ),
            'ui:widget': 'radio',
        },
        anyFurtherInformation_details: {
            'ui:title': 'Please provide full details*',
        },
    },
};

export default {
    type: 'object',
    properties: {
        driver: {
            type: 'object',
            required: ['insuredInCharge'],
            properties: {
                insuredInCharge: {
                    type: 'boolean',
                },
            },
            dependencies: {
                insuredInCharge: {
                    oneOf: [
                        {
                            properties: {
                                insuredInCharge: {
                                    enum: [true],
                                },
                            },
                        },
                        {
                            required: ['fullName', 'dob', 'phone', 'consent', 'insuranceRefused'],
                            properties: {
                                insuredInCharge: {
                                    enum: [false],
                                },
                                fullName: {
                                    type: 'string',
                                },
                                dob: {
                                    type: 'string',
                                    format: 'date',
                                },
                                email: {
                                    type: 'string',
                                },
                                phone: {
                                    type: 'string',
                                },
                                addressLine1: {
                                    type: 'string',
                                },
                                addressLine2: {
                                    type: 'string',
                                },
                                suburb: {
                                    type: 'string',
                                },
                                city: {
                                    type: 'string',
                                },
                                postcode: {
                                    type: 'integer',
                                },
                                consent: {
                                    type: 'boolean',
                                },
                                insuranceRefused: {
                                    type: 'object',
                                    required: ['hadInsuranceRefused'],
                                    properties: {
                                        hadInsuranceRefused: {
                                            type: 'boolean',
                                        },
                                    },
                                    dependencies: {
                                        hadInsuranceRefused: {
                                            oneOf: [
                                                {
                                                    properties: {
                                                        hadInsuranceRefused: {
                                                            enum: [false],
                                                        },
                                                    },
                                                },
                                                {
                                                    required: ['insuranceRefusedDetails'],
                                                    properties: {
                                                        hadInsuranceRefused: {
                                                            enum: [true],
                                                        },
                                                        insuranceRefusedDetails: {
                                                            type: 'string',
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                                accident: {
                                    type: 'object',
                                    required: ['hadAccident'],
                                    properties: {
                                        hadAccident: {
                                            type: 'boolean',
                                        },
                                    },
                                    dependencies: {
                                        hadAccident: {
                                            oneOf: [
                                                {
                                                    properties: {
                                                        hadAccident: {
                                                            enum: [false],
                                                        },
                                                    },
                                                },
                                                {
                                                    required: ['accidentDetails'],
                                                    properties: {
                                                        hadAccident: {
                                                            enum: [true],
                                                        },
                                                        accidentDetails: {
                                                            type: 'string',
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },

                                convicted: {
                                    type: 'object',
                                    required: ['beenConvicted'],
                                    properties: {
                                        beenConvicted: {
                                            type: 'boolean',
                                        },
                                    },
                                    dependencies: {
                                        beenConvicted: {
                                            oneOf: [
                                                {
                                                    properties: {
                                                        beenConvicted: {
                                                            enum: [false],
                                                        },
                                                    },
                                                },
                                                {
                                                    required: ['convictedDetails'],
                                                    properties: {
                                                        beenConvicted: {
                                                            enum: [true],
                                                        },
                                                        convictedDetails: {
                                                            type: 'string',
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    },
                                },
                            },
                            // required: ['fullName', 'dob', 'phone', 'consent', 'insuranceRefused'],
                        },
                    ],
                },
            },
        },
        licence: {
            type: 'object',
            title: 'Person in charge/ licence details',
            properties: {
                stepHeading: { type: 'null' },
                number: {
                    type: 'string',
                },
                appropriateClass: {
                    type: 'boolean',
                },
                appropriateEndorsements: {
                    type: 'boolean',
                },
                conditions: {
                    type: 'boolean',
                },
                type: {
                    type: 'string',
                    enum: ['Full', 'Restricted', 'Learner', 'International'],
                },
                country: {
                    type: 'string',
                    enum: [
                        'New Zealand',
                        'Afghanistan',
                        'Åland Islands',
                        'Albania',
                        'Algeria',
                        'American Samoa',
                        'Andorra',
                        'Angola',
                        'Anguilla',
                        'Antarctica',
                        'Antigua and Barbuda',
                        'Argentina',
                        'Armenia',
                        'Aruba',
                        'Australia',
                        'Austria',
                        'Azerbaijan',
                        'Bahamas',
                        'Bahrain',
                        'Bangladesh',
                        'Barbados',
                        'Belarus',
                        'Belgium',
                        'Belize',
                        'Benin',
                        'Bermuda',
                        'Bhutan',
                        'Bolivia',
                        'Bosnia and Herzegovina',
                        'Botswana',
                        'Bouvet Island',
                        'Brazil',
                        'British Indian Ocean Territory',
                        'British Virgin Islands',
                        'Brunei',
                        'Bulgaria',
                        'Burkina Faso',
                        'Burundi',
                        'Cambodia',
                        'Cameroon',
                        'Canada',
                        'Cape Verde',
                        'Caribbean Netherlands',
                        'Cayman Islands',
                        'Central African Republic',
                        'Chad',
                        'Chile',
                        'China',
                        'Christmas Island',
                        'Cocos (Keeling) Islands',
                        'Colombia',
                        'Comoros',
                        'Congo - Brazzaville',
                        'Congo - Kinshasa',
                        'Cook Islands',
                        'Costa Rica',
                        'Côte d’Ivoire',
                        'Croatia',
                        'Cuba',
                        'Cyprus',
                        'Czech Republic',
                        'Denmark',
                        'Djibouti',
                        'Dominica',
                        'Dominican Republic',
                        'Ecuador',
                        'Egypt',
                        'El Salvador',
                        'Equatorial Guinea',
                        'Eritrea',
                        'Estonia',
                        'Ethiopia',
                        'Falkland Islands',
                        'Faroe Islands',
                        'Fiji',
                        'Finland',
                        'France',
                        'French Guiana',
                        'French Polynesia',
                        'French Southern Territories',
                        'Gabon',
                        'Gambia',
                        'Georgia',
                        'Germany',
                        'Ghana',
                        'Gibraltar',
                        'Greece',
                        'Greenland',
                        'Grenada',
                        'Guadeloupe',
                        'Guam',
                        'Guatemala',
                        'Guernsey',
                        'Guinea',
                        'Guinea-Bissau',
                        'Guyana',
                        'Haiti',
                        'Heard &amp; McDonald Islands',
                        'Honduras',
                        'Hong Kong SAR China',
                        'Hungary',
                        'Iceland',
                        'India',
                        'Indonesia',
                        'Iran',
                        'Iraq',
                        'Ireland',
                        'Isle of Man',
                        'Israel',
                        'Italy',
                        'Jamaica',
                        'Japan',
                        'Jersey',
                        'Jordan',
                        'Kazakhstan',
                        'Kenya',
                        'Kiribati',
                        'Kuwait',
                        'Kyrgyzstan',
                        'Laos',
                        'Latvia',
                        'Lebanon',
                        'Lesotho',
                        'Liberia',
                        'Libya',
                        'Liechtenstein',
                        'Lithuania',
                        'Luxembourg',
                        'Macau SAR China',
                        'Macedonia',
                        'Madagascar',
                        'Malawi',
                        'Malaysia',
                        'Maldives',
                        'Mali',
                        'Malta',
                        'Marshall Islands',
                        'Martinique',
                        'Mauritania',
                        'Mauritius',
                        'Mayotte',
                        'Mexico',
                        'Micronesia',
                        'Moldova',
                        'Monaco',
                        'Mongolia',
                        'Montenegro',
                        'Montserrat',
                        'Morocco',
                        'Mozambique',
                        'Myanmar (Burma)',
                        'Namibia',
                        'Nauru',
                        'Nepal',
                        'Netherlands',
                        'Netherlands Antilles',
                        'New Caledonia',
                        'Nicaragua',
                        'Niger',
                        'Nigeria',
                        'Niue',
                        'Norfolk Island',
                        'North Korea',
                        'Northern Mariana Islands',
                        'Norway',
                        'Oman',
                        'Outlying Oceania',
                        'Pakistan',
                        'Palau',
                        'Palestinian Territories',
                        'Panama',
                        'Papua New Guinea',
                        'Paraguay',
                        'Peru',
                        'Philippines',
                        'Pitcairn Islands',
                        'Poland',
                        'Portugal',
                        'Puerto Rico',
                        'Qatar',
                        'Réunion',
                        'Romania',
                        'Russia',
                        'Rwanda',
                        'Saint Barthélemy',
                        'Saint Helena',
                        'Saint Kitts and Nevis',
                        'Saint Lucia',
                        'Saint Martin',
                        'Saint Pierre and Miquelon',
                        'Samoa',
                        'San Marino',
                        'São Tomé and Príncipe',
                        'Saudi Arabia',
                        'Senegal',
                        'Serbia',
                        'Seychelles',
                        'Sierra Leone',
                        'Singapore',
                        'Slovakia',
                        'Slovenia',
                        'Solomon Islands',
                        'Somalia',
                        'South Africa',
                        'South Georgia &amp; South Sandwich Islands',
                        'South Korea',
                        'Spain',
                        'Sri Lanka',
                        'St. Vincent &amp; Grenadines',
                        'Sudan',
                        'Suriname',
                        'Svalbard and Jan Mayen',
                        'Swaziland',
                        'Sweden',
                        'Switzerland',
                        'Syria',
                        'Taiwan',
                        'Tajikistan',
                        'Tanzania',
                        'Thailand',
                        'Timor-Leste',
                        'Togo',
                        'Tokelau',
                        'Tonga',
                        'Trinidad and Tobago',
                        'Tunisia',
                        'Turkey',
                        'Turkmenistan',
                        'Turks and Caicos Islands',
                        'Tuvalu',
                        'U.S. Outlying Islands',
                        'U.S. Virgin Islands',
                        'Uganda',
                        'Ukraine',
                        'United Arab Emirates',
                        'United Kingdom',
                        'United States',
                        'Uruguay',
                        'Uzbekistan',
                        'Vanuatu',
                        'Vatican City',
                        'Venezuela',
                        'Vietnam',
                        'Wallis and Futuna',
                        'Western Sahara',
                        'Yemen',
                        'Zambia',
                        'Zimbabwe',
                    ],
                },
            },
            required: [
                'number',
                'appropriateClass',
                'appropriateEndorsements',
                'conditions',
                'type',
                'country',
            ],
        },
    },
};

import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema.js'; // eslint-disable-line

const Incident = ({ saveContinue, saveLocalData, transformErrors, fileUpload }) => {
    const localData = JSON.parse(window.localStorage.getItem('accident'));
    const formData = localData && localData.incident ? localData.incident : {};

    const onSubmit = data => {
        const incident = data.formData;

        const ids = [];

        if (incident.sketches.listOfFiles && incident.sketches.listOfFiles.length) {
            incident.sketches.listOfFiles.map(
                file =>
                    file.file &&
                    fileUpload({ variables: { file: file.file } }).then(fileData => {
                        ids.push({ file: fileData.data.FileUpload });
                        localStorage.setItem('ids', JSON.stringify(ids));
                    })
            );
        }

        window.dataLayer.push({
            event: 'accidentClaimSubmission',
            step: 4,
            accident_date: incident.date,
            accident_time: incident.time,
            accident_location: incident.location,
            accident_speed_limit: incident.speedLimit,
            accident_speed: incident.speed ? Number(incident.speed) : 0,
            accident_liable_party: incident.fault.fault,
            documents_uploaded: ids,
            accident_driver_intoxicated: incident.drugsAlcohol.consumedDrugsAlcohol,
            accident_police_attended: incident.police.policeAttended,
            accident_police_breath_test: incident.police.test && incident.police.test.completed,
            accident_independent_witness: incident.witness.independentWitness,
        });

        incident.sketches.listOfFiles = [];
        saveLocalData({ incident });
        saveContinue();
    };

    const validate = (data, errors) => {
        const accidentDateError = "Sorry, the date of the accident can't be in the future";
        const validDate = dob => {
            const currentYear = new Date();
            const enteredDate = new Date(dob);

            return enteredDate <= currentYear;
        };

        if (data.date && validDate(data.date) === false) {
            errors.date.addError(accidentDateError);
        }

        const files = data.sketches.listOfFiles;
        if (files) {
            files.forEach((item, index) => {
                if (item.file) {
                    const fileSize = parseInt(
                        (item.file.replace(/=/g, '').length * 0.75) / 1e6,
                        10
                    );
                    if (fileSize > 20) {
                        errors.sketches.listOfFiles.addError(
                            `File ${index + 1} file size must be less than 20MB.`
                        );
                    }
                }
            });
        }

        return errors;
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    4.1. Accident details
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                validate={validate}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Incident.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
    fileUpload: () => {},
};

Incident.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
    fileUpload: PropTypes.func,
};

export default Incident;

import React from 'react';

const dateNow = new Date();
const endYear = dateNow.getFullYear() + 2;

export default {
    paymentOption: {
        classNames: 'payment-option',
        paymentOptionValue: {
            classNames: 'field-parent payment-enum',
            'ui:title': (
                <span>
                    <b>01.</b> Payment option*
                </span>
            ),
            'ui:widget': 'radio',
        },
        accountName: {
            'ui:title': (
                <span>
                    <b>01(A).</b> Account name*
                </span>
            ),
        },
        bankName: {
            'ui:title': (
                <span>
                    <b>01(b).</b> Bank name*
                </span>
            ),
        },
        bankDetail: {
            'ui:title': (
                <span>
                    <b>01(c).</b> Bank number*
                </span>
            ),
            bank: {
                'ui:placeholder': '##',
            },
            branch: {
                'ui:placeholder': '####',
            },
            accountNumber: {
                'ui:placeholder': '#######',
            },
            suffix: {
                'ui:placeholder': '###',
            },
        },
        firstPaymentDate: {
            classNames: 'field-date',
            'ui:title': (
                <span>
                    <b>02.</b> First payment date*
                </span>
            ),
            // 'ui:description': 'Please note the first payment date can’t be selected within 7 days of the chosen policy start date and it must be a weekday so the direct debit can be processed.',
            'ui:widget': 'alt-date',
            'ui:options': {
                hideNowButton: true,
                hideClearButton: true,
                yearsRange: [dateNow.getFullYear(), endYear],
            },
        },
        firstPaymentDateSubheading: {
            classNames: 'field-step-note-content field-step-note-content-payment',
            'ui:title':
                'Please note the first payment date can’t be selected within 7 days of the chosen policy start date and it must be a weekday so the direct debit can be processed.',
        },
    },
    notes: {
        'ui:title': (
            <span>
                <b>03.</b> Notes
            </span>
        ),
        'ui:placeholder': 'Write your notes here',
        'ui:widget': 'textarea',
        'ui:options': {
            rows: 8,
        },
    },
};

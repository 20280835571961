export default {
    make: {
        'ui:title': 'Make',
        'ui:placeholder': 'e.g. Toyota',
    },
    model: {
        'ui:title': 'Model',
        'ui:placeholder': 'e.g. Corolla',
    },
    registration: {
        'ui:title': 'What is your vehicle registration number?',
        'ui:placeholder': 'eg ABC123',
    },
    manufactureYear: {
        classNames: 'field-select',
        'ui:widget': 'select',
        'ui:title': "What was your vehicle's year of manufacture?",
        'ui:placeholder': 'Pick the year of manufacture',
    },
    conditionNote: {
        conditionNoteRequired: {
            classNames: 'field-parent',
            'ui:title': 'At the time of the accident did the vehicle have any defects? ',
            'ui:widget': 'radio',
        },
        conditionNote: {
            'ui:title': 'Please provide details',
        },
    },
    modifications: {
        modificationsRequired: {
            classNames: 'field-parent',
            'ui:title': 'Does your vehicle have any modifications?',
            'ui:widget': 'radio',
        },
        modifications: {
            'ui:title': 'Please provide details',
        },
    },
    endorsements: {
        'ui:title': 'Are any licence endorsements required to operate this vehicle?*',
        'ui:widget': 'radio',
    },
    owner: {
        'ui:title': 'Are you the sole owner of the vehicle?*',
        'ui:widget': 'radio',
    },
    finance: {
        financeOwing: {
            classNames: 'field-parent',
            'ui:title': 'Is there any finance owing on the vehicle?*',
            'ui:widget': 'radio',
        },

        financeCompany: {
            'ui:title': 'What is the name of the finance company',
            'ui:description': '',
        },

        financeOutstanding: {
            'ui:title': 'What amount is still outstanding to the finance company?',
            'ui:description': '',
        },
    },
};

import 'isomorphic-unfetch';
import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { StepProgress } from './components';

import { Damage, Declaration, Driver, Incident, Policy, Vehicle } from './steps';

// eslint-disable-next-line react/prop-types
const AccidentFormApp = () => {
    const [formError, setFormError] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [step, setStep] = useState(0);

    const localData = JSON.parse(window.localStorage.getItem('accident'));

    useEffect(() => {
        if (localData && localData.step && localData.step < step) {
            setStep(localData.step);
        }
    });

    const steps = ['Policy', 'Vehicle', 'Driver', 'Incident', 'Damage', 'Declaration'];

    const saveLocalData = data => {
        window.localStorage.setItem(
            'accident',
            JSON.stringify({
                ...localData,
                ...data,
                step: step + 1,
            })
        );
    };

    /* eslint-disable */
    const [submitForm] = useMutation(
        gql`
            mutation Accident(
                $PolicyHolderFullName: String!
                $PolicyHolderEmail: String!
                $PolicyHolderPhone: String!
                $PolicyHolderAddressLine1: String
                $PolicyHolderAddressLine2: String
                $PolicyHolderSuburb: String
                $PolicyHolderCity: String
                $PolicyHolderPostcode: String
                $InsuredVehicleMake: String
                $InsuredVehicleModel: String
                $InsuredVehicleManufactureYear: Int
                $GeneralConditionBeforeAccident: Boolean
                $GeneralConditionBeforeAccident_Yes_Describe: String
                $InsuredVehicleRegistrationOrVIN: String
                $AnyLicenceEndorsementsRequired: Boolean!
                $SoleOwner: Boolean!
                $SoleOwner_No_ContactName: String
                $SoleOwner_No_ContactDetails: String
                $InsuredVehicleHaveFinancialInterest: Boolean
                $InsuredVehicleHaveFinancialInterest_Company: String
                $InsuredVehicleHaveFinancialInterest_OutstandingAmount: String
                $WasPolicyHolderInCharge: Boolean!
                $InChargePersonFullName: String
                $InChargePersonBirthDate: String
                $InChargePersonAddressLine1: String
                $InChargePersonAddressLine2: String
                $InChargePersonSuburb: String
                $InChargePersonCity: String
                $InChargePersonPostcode: String
                $InChargePersonEmail: String
                $InChargePersonPhone: String
                $HasOwnersConsent: Boolean
                $HasInsuranceRefusedIn5Years: Boolean
                $HasInsuranceRefusedIn5Years_Yes_Describe: String
                $HasInvolvedAccidentIn5Years: Boolean
                $HasInvolvedAccidentIn5Years_Yes_Describe: String
                $HasInfringementIn5Years: Boolean
                $HasInfringementIn5Years_Yes_Describe: String
                $LicenceDOB: String
                $LicenceFullName: String
                $LicenceNumber: String
                $LicenceNumberVersion: String
                $HasAppropriateLicence: Boolean!
                $HasAppropriateEndorsements: Boolean!
                $AnyConditionsOnLicence: Boolean!
                $LicenceType: String!
                $Licence_CountryOfIssue: String!
                $IncidentDate: String!
                $IncidentTime: String!
                $IncidentLocation: String!
                $SpeedLimit: String
                $DrivingSpeed: Int
                $DescribeIncident: String!
                $WhosFault: String
                $FaultPartyLiable: String
                $DriverIntoxicatedIn12Hours: Boolean!
                $DriverIntoxicatedIn12Hours_Yes_Quantity: String
                $DriverIntoxicatedIn12Hours_Yes_SubstancesTime: String
                $DidPoliceAttend: Boolean!
                $PoliceReportNumber: String
                $BreathAlyserTest: Boolean
                $BreathAlyserTest_Yes_Result: String
                $SketchPlans: [FileInputType]
                $WasIndependentWitness: Boolean!
                $WitnessName: String
                $WitnessPhone: String
                $RelationshipToWitness: String
                $DamageToYourVehicle: String!
                $IsPolicyHolderBestContact: Boolean!
                $BestContactName: String
                $BestContactPhone: String
                $BestContactEmail: String
                $HavePreferredRepairer: Boolean
                $PreferredRepairerName: String
                $PreferredRepairerAddress: String
                $PreferredRepairerPhone: String
                $ObtainedRepairQuote: Boolean
                $ObtainedRepairQuote_Yes_Estimate: String
                $VehicleLocationAndDetails: String!
                $AnotherVehicleInvolved: Boolean!
                $DamagedVehicles: [DamagedVehicleInputType]
                $NonVehiclePropertyInvolved: Boolean!
                $NonVehiclePropertyInvolved_Yes_Describe: String
                $AnyoneInjured: Boolean!
                $InjuredName: String
                $InjuredContactNumber: String
                $EmergencyServicesAttended: Boolean
                $EmergencyServicesAttended_Yes_Describe: String
                $AnyoneTakenToHospital: Boolean
                $DeclarationRelevantInformation: String
                $DisputeTerm: Boolean!
                $FurtherInformation: Boolean!
                $ReleaseDocsToPolice: Boolean!
                $AuthorityToDisclosurePersonalInfoFromOtherParties: Boolean!
                $LumleyReleasingToOtherPartiesPersonalInformation: Boolean!
                $AgentObtainPersonalInformationFromOtherParties: Boolean!
            ) {
                AccidentClaim(
                    PolicyHolder: {
                        FullName: $PolicyHolderFullName
                        Email: $PolicyHolderEmail
                        Phone: $PolicyHolderPhone
                        Address: {
                            AddressLine1: $PolicyHolderAddressLine1
                            AddressLine2: $PolicyHolderAddressLine2
                            Suburb: $PolicyHolderSuburb
                            City: $PolicyHolderCity
                            Postcode: $PolicyHolderPostcode
                        }
                    }

                    InsuredVehicle: {
                        Make: $InsuredVehicleMake
                        Model: $InsuredVehicleModel
                        ManufactureYear: $InsuredVehicleManufactureYear
                        RegistrationOrVIN: $InsuredVehicleRegistrationOrVIN
                        HaveFinancialInterest: $InsuredVehicleHaveFinancialInterest
                        HaveFinancialInterest_Company: $InsuredVehicleHaveFinancialInterest_Company
                        HaveFinancialInterest_OutstandingAmount: $InsuredVehicleHaveFinancialInterest_OutstandingAmount
                    }
                    GeneralConditionBeforeAccident: $GeneralConditionBeforeAccident
                    GeneralConditionBeforeAccident_Yes_Describe: $GeneralConditionBeforeAccident_Yes_Describe
                    AnyLicenceEndorsementsRequired: $AnyLicenceEndorsementsRequired
                    SoleOwner: $SoleOwner
                    SoleOwner_No_ContactName: $SoleOwner_No_ContactName
                    SoleOwner_No_ContactDetails: $SoleOwner_No_ContactDetails
                    WasPolicyHolderInCharge: $WasPolicyHolderInCharge
                    InChargePerson: {
                        FullName: $InChargePersonFullName
                        BirthDate: $InChargePersonBirthDate
                        Address: {
                            AddressLine1: $InChargePersonAddressLine1
                            AddressLine2: $InChargePersonAddressLine2
                            Suburb: $InChargePersonSuburb
                            City: $InChargePersonCity
                            Postcode: $InChargePersonPostcode
                        }
                        Email: $InChargePersonEmail
                        Phone: $InChargePersonPhone
                    }
                    HasOwnersConsent: $HasOwnersConsent
                    HasInsuranceRefusedIn5Years: $HasInsuranceRefusedIn5Years
                    HasInsuranceRefusedIn5Years_Yes_Describe: $HasInsuranceRefusedIn5Years_Yes_Describe
                    HasInvolvedAccidentIn5Years: $HasInvolvedAccidentIn5Years
                    HasInvolvedAccidentIn5Years_Yes_Describe: $HasInvolvedAccidentIn5Years_Yes_Describe
                    HasInfringementIn5Years: $HasInfringementIn5Years
                    HasInfringementIn5Years_Yes_Describe: $HasInfringementIn5Years_Yes_Describe
                    LicenceFullName: $LicenceFullName
                    LicenceDOB: $LicenceDOB
                    LicenceNumber: $LicenceNumber
                    LicenceNumberVersion: $LicenceNumberVersion
                    HasAppropriateLicence: $HasAppropriateLicence
                    HasAppropriateEndorsements: $HasAppropriateEndorsements
                    AnyConditionsOnLicence: $AnyConditionsOnLicence
                    LicenceType: $LicenceType
                    Licence_CountryOfIssue: $Licence_CountryOfIssue
                    IncidentDate: $IncidentDate
                    IncidentTime: $IncidentTime
                    IncidentLocation: $IncidentLocation
                    SpeedLimit: $SpeedLimit
                    DrivingSpeed: $DrivingSpeed
                    DescribeIncident: $DescribeIncident
                    WhosFault: $WhosFault
                    FaultPartyLiable: $FaultPartyLiable
                    DriverIntoxicatedIn12Hours: $DriverIntoxicatedIn12Hours
                    DriverIntoxicatedIn12Hours_Yes_Quantity: $DriverIntoxicatedIn12Hours_Yes_Quantity
                    DriverIntoxicatedIn12Hours_Yes_SubstancesTime: $DriverIntoxicatedIn12Hours_Yes_SubstancesTime
                    DidPoliceAttend: $DidPoliceAttend
                    PoliceReportNumber: $PoliceReportNumber
                    BreathAlyserTest: $BreathAlyserTest
                    BreathAlyserTest_Yes_Result: $BreathAlyserTest_Yes_Result
                    SketchPlans: $SketchPlans
                    WasIndependentWitness: $WasIndependentWitness
                    WitnessName: $WitnessName
                    WitnessPhone: $WitnessPhone
                    RelationshipToWitness: $RelationshipToWitness
                    DamageToYourVehicle: $DamageToYourVehicle
                    IsPolicyHolderBestContact: $IsPolicyHolderBestContact
                    BestContactName: $BestContactName
                    BestContactPhone: $BestContactPhone
                    BestContactEmail: $BestContactEmail
                    HavePreferredRepairer: $HavePreferredRepairer
                    PreferredRepairerName: $PreferredRepairerName
                    PreferredRepairerAddress: $PreferredRepairerAddress
                    PreferredRepairerPhone: $PreferredRepairerPhone
                    ObtainedRepairQuote: $ObtainedRepairQuote
                    ObtainedRepairQuote_Yes_Estimate: $ObtainedRepairQuote_Yes_Estimate
                    VehicleLocationAndDetails: $VehicleLocationAndDetails
                    AnotherVehicleInvolved: $AnotherVehicleInvolved
                    DamagedVehicles: $DamagedVehicles
                    NonVehiclePropertyInvolved: $NonVehiclePropertyInvolved
                    NonVehiclePropertyInvolved_Yes_Describe: $NonVehiclePropertyInvolved_Yes_Describe
                    AnyoneInjured: $AnyoneInjured
                    InjuredName: $InjuredName
                    InjuredContactNumber: $InjuredContactNumber
                    EmergencyServicesAttended: $EmergencyServicesAttended
                    EmergencyServicesAttended_Yes_Describe: $EmergencyServicesAttended_Yes_Describe
                    AnyoneTakenToHospital: $AnyoneTakenToHospital

                    RelevantInformation: $DeclarationRelevantInformation

                    DisputeTerm: $DisputeTerm
                    FurtherInformation: $FurtherInformation
                    ReleaseDocsToPolice: $ReleaseDocsToPolice
                    AuthorityToDisclosurePersonalInfoFromOtherParties: $AuthorityToDisclosurePersonalInfoFromOtherParties
                    LumleyReleasingToOtherPartiesPersonalInformation: $LumleyReleasingToOtherPartiesPersonalInformation
                    AgentObtainPersonalInformationFromOtherParties: $AgentObtainPersonalInformationFromOtherParties
                ) {
                    PolicyHolder {
                        FullName
                    }
                }
            }
        `,
        {
            onCompleted: data => handleSuccess(data),
            onError: errors => handleError(errors),
        }
    );

    const [fileUpload] = useMutation(gql`
        mutation FileUpload($file: String!) {
            FileUpload(file: $file)
        }
    `);

    const saveContinue = () => {
        setStep(step + 1);
        window.scrollTo(0, 0);
    };

    const handleSuccess = () => {
        window.dataLayer.push({
            event: 'accidentClaimSubmission',
            step: 7,
        });

        window.localStorage.removeItem('accident'); // @TODO: Enable on line - Aaron Rose

        if (window.location.href.includes('?')) {
            window.location.href += '&fs=1';
        } else {
            window.location.href += '?fs=1';
        }
    };

    const handleError = error => {
        if (error) {
            setFormError();
            console.error(error); // eslint-disable-line no-console
        }
    };

    const saveFinish = () => {
        window.scrollTo(0, 0);
        setIsSubmitting(true);

        const { policy, vehicle, driver, incident, damage, declaration } = JSON.parse(
            window.localStorage.getItem('accident')
        );
        const { anotherVehicle } = damage;
        const { anotherVehicleDamaged } = anotherVehicle;

        const ids = JSON.parse(window.localStorage.getItem('ids'));

        let damagedVehicles = [];

        const makeVehicle = vehicle => ({
            AnotherVehicleOwnerName: vehicle.ownerName,
            AnotherVehicleDriverName: vehicle.driverName,
            AnotherVehiclePhone: vehicle.phone,
            AnotherVehicleRegistration: vehicle.registration,
            AnotherVehicleMake: vehicle.make,
            AnotherVehicleModel: vehicle.model,
            AnotherVehicleInsurance: vehicle.insurance,
        });

        // Check if other vehicles were damaged
        if (anotherVehicleDamaged) {
            const { additionalVehicles, vehicle } = anotherVehicle;

            // Add each additional vehicle
            if (additionalVehicles) {
                damagedVehicles = additionalVehicles.map(makeVehicle);
            }

            // Add first vehicle
            if (vehicle) {
                damagedVehicles.unshift(makeVehicle(vehicle));
            }
        }

        submitForm({
            onError: error => handleError(error),
            onCompleted: data => handleSuccess(data),
            variables: {
                PolicyHolderFullName: policy.fullName,
                PolicyHolderEmail: policy.email,
                PolicyHolderPhone: policy.phone,
                PolicyHolderReference: policy.clientReference,
                PolicyHolderAddressLine1: policy.addressLine1,
                PolicyHolderAddressLine2: policy.addressLine2,
                PolicyHolderSuburb: policy.suburb,
                PolicyHolderCity: policy.city,
                PolicyHolderPostcode: policy.postCode,

                InsuredVehicleMake: vehicle.make,
                InsuredVehicleModel: vehicle.model,
                InsuredVehicleManufactureYear: Number(vehicle.manufactureYear),
                GeneralConditionBeforeAccident: vehicle.condition.hasDefects,
                GeneralConditionBeforeAccident_Yes_Describe: vehicle.condition.details,
                InsuredVehicleRegistrationOrVIN: vehicle.registration,
                Modifications: vehicle.modifications.modificationsRequired,
                Modifications_Yes_Describe: vehicle.modifications.modificationsDetails,
                AnyLicenceEndorsementsRequired: vehicle.endorsementsRequired,
                SoleOwner: vehicle.owner.soleOwner,
                SoleOwner_No_ContactName: vehicle.owner.interestedParties,
                SoleOwner_No_ContactDetails: vehicle.owner.interestedPartiesContact,
                InsuredVehicleHaveFinancialInterest: vehicle.finance.financeOwing,
                InsuredVehicleHaveFinancialInterest_Company: vehicle.finance.financeCompany,
                InsuredVehicleHaveFinancialInterest_OutstandingAmount:
                    vehicle.finance.financeOutstanding,

                WasPolicyHolderInCharge: driver.driver.insuredInCharge,
                InChargePersonFullName: driver.driver.fullName,
                InChargePersonBirthDate: driver.driver.dob,
                InChargePersonAddressLine1: driver.driver.addressLine1,
                InChargePersonAddressLine2: driver.driver.addressLine2,
                InChargePersonSuburb: driver.driver.suburb,
                InChargePersonCity: driver.driver.city,
                InChargePersonPostcode: driver.driver.postcode,
                InChargePersonEmail: driver.driver.email,
                InChargePersonPhone: driver.driver.phone,

                HasOwnersConsent: driver.driver.consent,
                HasInsuranceRefusedIn5Years:
                    driver.driver.insuranceRefused &&
                    driver.driver.insuranceRefused.hadInsuranceRefused,
                HasInsuranceRefusedIn5Years_Yes_Describe:
                    driver.driver.insuranceRefused &&
                    driver.driver.insuranceRefused.insuranceRefusedDetails,
                HasInvolvedAccidentIn5Years:
                    driver.driver.accident && driver.driver.accident.hadAccident,
                HasInvolvedAccidentIn5Years_Yes_Describe:
                    driver.driver.accident && driver.driver.accident.accidentDetails,
                HasInfringementIn5Years:
                    driver.driver.convicted && driver.driver.convicted.beenConvicted,
                HasInfringementIn5Years_Yes_Describe:
                    driver.driver.convicted && driver.driver.convicted.convictedDetails,
                LicenceFullName: driver.licence.fullName,
                LicenceDOB: driver.licence.dob,
                LicenceNumber: driver.licence.number,
                LicenceNumberVersion: driver.licence.version,
                HasAppropriateLicence: driver.licence.appropriateClass,
                HasAppropriateEndorsements: driver.licence.appropriateEndorsements,
                AnyConditionsOnLicence: driver.licence.conditions,
                LicenceType: driver.licence.type,
                Licence_CountryOfIssue: driver.licence.country,

                IncidentDate: incident.date,
                IncidentTime: incident.time,
                IncidentLocation: incident.location,
                SpeedLimit: incident.speedLimit,
                DrivingSpeed: Number(incident.speed),
                DescribeIncident: incident.description,
                WhosFault: incident.fault.fault,
                FaultPartyLiable: incident.fault.reason,
                DriverIntoxicatedIn12Hours: incident.drugsAlcohol.consumedDrugsAlcohol,
                DriverIntoxicatedIn12Hours_Yes_Quantity: incident.drugsAlcohol.substancesQty,
                DriverIntoxicatedIn12Hours_Yes_SubstancesTime: incident.drugsAlcohol.time,
                DidPoliceAttend: incident.police.policeAttended,
                PoliceReportNumber: incident.police.reference,
                BreathAlyserTest: incident.police.test && incident.police.test.completed,
                BreathAlyserTest_Yes_Result: incident.police.test && incident.police.test.result,
                SketchPlans: ids,
                WasIndependentWitness: incident.witness.independentWitness,
                WitnessName: incident.witness.witnessName,
                WitnessPhone: incident.witness.witnessPhone,
                RelationshipToWitness: incident.witness.witnessPartyRelationships,

                DamageToYourVehicle: damage.details,
                IsPolicyHolderBestContact: damage.bestContact.isPolicyHolder,
                BestContactName: damage.bestContact.name,
                BestContactPhone: damage.bestContact.phone,
                BestContactEmail: damage.bestContact.address,
                BestContactAddress: damage.bestContact.address,
                HavePreferredRepairer: damage.preferredRepairer.hasPreferredRepairer,
                PreferredRepairerName: damage.preferredRepairer.name,
                PreferredRepairerAddress: damage.preferredRepairer.address,
                PreferredRepairerPhone: damage.preferredRepairer.phone,
                ObtainedRepairQuote:
                    damage.preferredRepairer.quote && damage.preferredRepairer.quote.obtainedQuote,
                ObtainedRepairQuote_Yes_Estimate:
                    damage.preferredRepairer.quote && damage.preferredRepairer.quote.quoteEstimate,
                VehicleLocationAndDetails: damage.vehicleLocation,
                AnotherVehicleInvolved: anotherVehicleDamaged,
                DamagedVehicles: damagedVehicles,
                NonVehiclePropertyInvolved: damage.nonVehicle.nonVehiclePropertyDamaged,
                NonVehiclePropertyInvolved_Yes_Describe:
                    damage.nonVehicle.nonVehiclePropertyDamageDetails,
                AnyoneInjured: damage.injuries.anyInjuries,
                InjuredName: damage.injuries.names,
                InjuredContactNumber: damage.injuries.phones,
                EmergencyServicesAttended:
                    damage.injuries.emergencyServices &&
                    damage.injuries.emergencyServices.emergencyServicesAttended,
                EmergencyServicesAttended_Yes_Describe:
                    damage.injuries.emergencyServices &&
                    damage.injuries.emergencyServices.emergencyServicesDetails,
                AnyoneTakenToHospital: damage.injuries.hospital,

                DeclarationRelevantInformation:
                    declaration.additionalInformation.hasAdditionalInformation &&
                    declaration.additionalInformation.details,

                DisputeTerm: declaration.paymentDispute,
                FurtherInformation: declaration.furtherInformation,
                ReleaseDocsToPolice: declaration.authorisation1,
                AuthorityToDisclosurePersonalInfoFromOtherParties: declaration.authorisation2,
                LumleyReleasingToOtherPartiesPersonalInformation: declaration.authorisation3,
                AgentObtainPersonalInformationFromOtherParties: declaration.authorisation4,
            },
        });
    };

    const transformErrors = errors =>
        errors.map(error => {
            /* eslint-disable */
            if (error.name === 'required') error.message = 'Required';
            if (error.name === 'enum') error.message = 'More information required';

            if (error.name === 'format' && error.params.format === 'email') {
                error.message = 'Invalid email address';
            }

            if (error.name === 'oneOf') error.message = '';
            /* eslint-enable */

            return error;
        });

    const renderFormStep = () => {
        switch (step) {
            case 0:
                return (
                    <Policy
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 1:
                return (
                    <Vehicle
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 2:
                return (
                    <Driver
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 3:
                return (
                    <Incident
                        saveContinue={saveContinue}
                        fileUpload={fileUpload}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 4:
                return (
                    <Damage
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 5:
                if (isSubmitting) {
                    return (
                        <div>
                            <h1>We are processing your request</h1>
                            <p>
                                We appreciate your your time and in a moment you will be notified
                                with a a confirmation email.
                            </p>
                        </div>
                    );
                }

                return (
                    <Declaration
                        saveFinish={saveFinish}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            default:
                return <div>Form complete</div>;
        }
    };

    return (
        <div className="l-multi-step-form">
            <StepProgress
                steps={steps}
                currentStep={step}
                setStep={targetStep => () => setStep(targetStep)}
            />
            <div className="l-multi-step-form__wrapper">
                {formError && (
                    <div>
                        <strong>Error:</strong> {formError}
                    </div>
                )}
                {renderFormStep()}
                {!isSubmitting && (
                    <div className="fineprint">
                        All claims are subject to the underwriter's acceptance of cover.
                        <br />
                        See our&nbsp;
                        <a rel="noopener noreferrer" target="_blank" href="/forms-and-policies/">
                            policy wording
                        </a>
                        &nbsp; for more information.
                    </div>
                )}
            </div>
            {/* {step > 0 && <button type="button" onClick={back}>Go Back</button>} */}
            <StepProgress
                steps={steps}
                currentStep={step}
                setStep={targetStep => () => setStep(targetStep)}
            />
        </div>
    );
};

export default AccidentFormApp;

export default {
    details: {
        'ui:title': 'What damage was done to your vehicle?*',
        'ui:widget': 'textarea',
        'ui:options': {
            rows: 5,
        },
    },
    bestContact: {
        isPolicyHolder: {
            classNames: 'field-parent',
            'ui:title':
                'Is the policy holder named in section 1 the best contact if we need to arrange an inspection of the vehicle?*',
            'ui:widget': 'radio',
        },
        name: {
            'ui:title': 'Contact name*',
        },
        phone: {
            'ui:title': 'Contact phone*',
        },
        address: {
            'ui:title': 'Contact address',
        },
    },
    preferredRepairer: {
        hasPreferredRepairer: {
            classNames: 'field-parent',
            'ui:title': 'Do you have a preferred repairer? (Optional)',
            'ui:widget': 'radio',
        },
        name: {
            'ui:title': 'Preferred repairer name (Optional)',
        },
        address: {
            'ui:title': 'Preferred repairer address (Optional)',
        },
        phone: {
            'ui:title': 'Preferred repairer phone (Optional)',
        },
        quote: {
            obtainedQuote: {
                classNames: 'field-parent',
                'ui:title': 'Have you obtained a quote for repair? (Optional)',
                'ui:widget': 'radio',
            },
            quoteEstimate: {
                'ui:title': 'What was the estimate? (Optional)',
            },
        },
    },

    vehicleLocation: {
        'ui:title': 'Where is the vehicle now?*',
    },

    anotherVehicle: {
        stepHeading: {
            classNames: 'field-step-heading',
            'ui:title': '5.2. Details of other vehicles',
            // 'ui:description': '5.2.',
        },
        anotherVehicleDamaged: {
            classNames: 'field-parent',
            'ui:title': 'Was another vehicle involved in the incident?*',
            'ui:widget': 'radio',
        },
        vehicle: {
            ownerName: {
                'ui:title': 'Owner name*',
            },
            driverName: {
                'ui:title': 'Driver name*',
            },
            phone: {
                'ui:title': 'Phone number*',
            },
            registration: {
                'ui:title': 'Registration*',
            },
            make: {
                'ui:title': 'Make*',
            },
            model: {
                'ui:title': 'Model*',
            },
            insurance: {
                'ui:title': 'Their insurance company details*',
            },
        },
        additionalVehicles: {
            classNames: 'field-damaged-vehicles',
            'ui:options': {
                orderable: false,
            },
            items: {
                ownerName: {
                    'ui:title': 'Owner name',
                },
                driverName: {
                    'ui:title': 'Driver name',
                },
                phone: {
                    'ui:title': 'Phone number',
                },
                registration: {
                    'ui:title': 'Registration',
                },
                make: {
                    'ui:title': 'Make',
                },
                model: {
                    'ui:title': 'Model',
                },
                insurance: {
                    'ui:title': 'Their insurance company details',
                },
            },
        },
    },

    nonVehicle: {
        stepHeading: {
            classNames: 'field-step-heading',
            'ui:title': '5.3. Non vehicle property ',
            // 'ui:description': '5.3.',
        },
        nonVehiclePropertyDamaged: {
            classNames: 'field-parent',
            'ui:title': 'Was any non-vehicle property involved in the incident?*',
            'ui:widget': 'radio',
        },
        nonVehiclePropertyDamageDetails: {
            'ui:title': 'Please describe*',
        },
    },

    injuries: {
        stepHeading: {
            classNames: 'field-step-heading',
            'ui:title': '5.4. Injuries',
            // 'ui:description': '5.4.',
        },
        anyInjuries: {
            classNames: 'field-parent',
            'ui:title': 'Did anyone get hurt in the incident?*',
            'ui:widget': 'radio',
        },
        names: {
            'ui:title': 'Injured name(s)*',
        },
        phones: {
            'ui:title': 'Injured contact number(s)*',
        },
        emergencyServices: {
            emergencyServicesAttended: {
                classNames: 'field-parent',
                'ui:widget': 'radio',
                'ui:title': 'Did emergency services attend?*',
            },
            emergencyServicesDetails: {
                'ui:title': 'Please provide details of which services attended*',
            },
        },
        hospital: {
            'ui:widget': 'radio',
            'ui:title': 'Was anyone taken to hospital?',
        },
    },
};

import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { StepProgress } from './components';

import { Policy, Vehicle, Driver, Theft, Recovery, Declaration } from './steps';

const TheftForm = () => {
    const [formError, setFormError] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [step, setStep] = useState(0);

    const localData = JSON.parse(window.localStorage.getItem('theft'));

    const steps = ['Policy', 'Vehicle', 'Driver', 'Theft', 'Recovery', 'Declaration'];

    const handleSuccess = () => {
        window.dataLayer.push({
            event: 'theftClaimSubmission',
            step: 7,
        });

        window.localStorage.removeItem('theft'); // @TODO: Enable on live - Aaron Rose

        if (window.location.href.includes('?')) {
            window.location.href += '&fs=1';
        } else {
            window.location.href += '?fs=1';
        }
    };

    const handleError = error => {
        if (error) {
            setFormError();
            console.error(error); // eslint-disable-line no-console
        }
    };

    const [submitForm] = useMutation(
        gql`
            mutation Theft(
                $PolicyHolderFullName: String!
                $PolicyHolderEmail: String!
                $PolicyHolderPhone: String!
                $PolicyHolderAddressLine1: String
                $PolicyHolderAddressLine2: String
                $PolicyHolderSuburb: String
                $PolicyHolderCity: String
                $PolicyHolderPostcode: String
                # $fPolicyVehiclePermanentResidence: Boolean!
                $InsuredVehicleMake: String
                $InsuredVehicleModel: String
                $InsuredVehicleManufactureYear: Int!
                $InsuredVehicleCondition: Boolean!
                $InsuredVehicleCondition_Yes_Describe: String
                $InsuredVehicleRegistrationOrVIN: String
                # $InsuredVehicleAnyLicenceEndorsementsRequired: Boolean!
                # $InsuredVehicleAnyLicenceEndorsementsRequired_Yes_Describe: String
                $InsuredVehicleSoleOwner: Boolean
                # $InsuredVehicleSoleOwner_No_ContactName: String
                # $InsuredVehicleSoleOwner_No_ContactDetails: String
                $InsuredVehicleHaveFinancialInterest: Boolean
                $InsuredVehicleHaveFinancialInterest_Company: String
                $InsuredVehicleHaveFinancialInterest_OutstandingAmount: String
                $DriverPolicyHolderInCharge: Boolean!
                $DriverFullName: String
                $DriverBirthDate: String
                $DriverAddressLine1: String
                $DriverAddressLine2: String
                $DriverSuburb: String
                $DriverCity: String
                $DriverPostcode: String
                $DriverEmail: String
                $DriverPhone: String
                $DriverRelationship: String
                $DriverOwnersConsent: Boolean
                $DriverInsuranceRefusedIn5Years: Boolean
                $DriverInsuranceRefusedIn5Years_Yes_Describe: String
                $DriverInvolvedAccidentIn5Years: Boolean
                $DriverInvolvedAccidentIn5Years_Yes_Describe: String
                $DriverInfringementIn5Years: Boolean
                $DriverInfringementIn5Years_Yes_Describe: String
                $TheftDate: String!
                $TheftTime: String!
                $TheftWhenOccured: String
                $TheftLocation: String!
                $TheftWhenVehicleLastSeen: String!
                $TheftHowVehicleHoused: String!
                $TheftWasVehicleLocked: String
                $TheftWhereWereKeys: String!
                $TheftWhereAreKeysNow: String!
                $TheftOnlyPartsOfVehicleStolen: Boolean
                $TheftOnlyPartsOfVehicleStolen_Yes_Describe: String
                $TheftHasTheftReportedToPolice: Boolean!
                $TheftPoliceOfficerName: String
                $TheftPoliceOfficerNumber: String
                $TheftPoliceReportNumber: String
                $TheftWhenPoliceNotified: String
                $TheftAnySuspicionsOffender: Boolean
                $TheftAnySuspicionsOffender_Yes_Describe: String
                $TheftAnySuspicionsOffender_Yes_Relationship: String
                $SketchPlans: [FileInputType]
                $RecoveryVehicleRecovered: Boolean!
                $RecoveryDate: String
                $RecoveryTime: String
                $RecoveryRecoveredLocation: String
                $RecoveryVehicleCurrentLocation: String
                $RecoveryWhoRecovered: String
                $RecoveryWasTowingCompanyUsed: Boolean
                $RecoveryWasTowingCompanyUsed_Yes_Details: String
                $RecoveryDescribeStolenDamangedParts: String
                $RecoveryHavePreferredRepairer: Boolean
                $RecoveryPreferredRepairerName: String
                $RecoveryPreferredRepairerAddress: String
                $RecoveryPreferredRepairerPhone: String
                $RecoveryObtainedRepairQuote: Boolean
                $RecoveryObtainedRepairQuote_Yes_Estimate: String
                $RecoveryIsPolicyHolderBestContact: Boolean
                $RecoveryBestContactName: String
                $RecoveryBestContactEmail: String
                $RecoveryBestContactPhone: String
                $DeclarationRelevantInformation: String
                $DeclarationDisputeTerm: Boolean!
                $FurtherInformation: Boolean!
                $DeclarationReleaseDocsToPolice: Boolean!
                $DeclarationDiscloseOtherParties: Boolean!
                $DeclarationLumleyPersonalInformation: Boolean!
                $DeclarationAgentObtainOtherParties: Boolean!
            ) {
                TheftClaim(
                    PolicyHolder: {
                        FullName: $PolicyHolderFullName
                        Email: $PolicyHolderEmail
                        Phone: $PolicyHolderPhone
                        Address: {
                            AddressLine1: $PolicyHolderAddressLine1
                            AddressLine2: $PolicyHolderAddressLine2
                            Suburb: $PolicyHolderSuburb
                            City: $PolicyHolderCity
                            Postcode: $PolicyHolderPostcode
                        }
                    }
                    # IsPermanentResidence: $PolicyVehiclePermanentResidence
                    InsuredVehicle: {
                        Make: $InsuredVehicleMake
                        Model: $InsuredVehicleModel
                        ManufactureYear: $InsuredVehicleManufactureYear
                        RegistrationOrVIN: $InsuredVehicleRegistrationOrVIN
                        HaveFinancialInterest: $InsuredVehicleHaveFinancialInterest
                        HaveFinancialInterest_Company: $InsuredVehicleHaveFinancialInterest_Company
                        HaveFinancialInterest_OutstandingAmount: $InsuredVehicleHaveFinancialInterest_OutstandingAmount
                    }
                    # AnyLicenceEndorsementsRequired: $InsuredVehicleAnyLicenceEndorsementsRequired
                    # AnyLicenceEndorsementsRequired_Yes_Describe: $InsuredVehicleAnyLicenceEndorsementsRequired_Yes_Describe
                    GeneralConditionBeforeTheft: $InsuredVehicleCondition
                    GeneralConditionBeforeTheft_Yes_Describe: $InsuredVehicleCondition_Yes_Describe
                    SoleOwner: $InsuredVehicleSoleOwner
                    # SoleOwner_No_ContactName: $InsuredVehicleSoleOwner_No_ContactName
                    # SoleOwner_No_ContactDetails: $InsuredVehicleSoleOwner_No_ContactDetails
                    WasPolicyHolderInCharge: $DriverPolicyHolderInCharge
                    InChargePerson: {
                        FullName: $DriverFullName
                        BirthDate: $DriverBirthDate
                        Address: {
                            AddressLine1: $DriverAddressLine1
                            AddressLine2: $DriverAddressLine2
                            Suburb: $DriverSuburb
                            City: $DriverCity
                            Postcode: $DriverPostcode
                        }
                        Email: $DriverEmail
                        Phone: $DriverPhone
                        Relation: $DriverRelationship
                    }
                    HasOwnersConsent: $DriverOwnersConsent
                    HasInsuranceRefusedIn5Years: $DriverInsuranceRefusedIn5Years
                    HasInsuranceRefusedIn5Years_Yes_Describe: $DriverInsuranceRefusedIn5Years_Yes_Describe
                    HasInvolvedAccidentIn5Years: $DriverInvolvedAccidentIn5Years
                    HasInvolvedAccidentIn5Years_Yes_Describe: $DriverInvolvedAccidentIn5Years_Yes_Describe
                    HasInfringementIn5Years: $DriverInfringementIn5Years
                    HasInfringementIn5Years_Yes_Describe: $DriverInfringementIn5Years_Yes_Describe
                    TheftDate: $TheftDate
                    TheftTime: $TheftTime
                    WhenTheftHadOccoured: $TheftWhenOccured
                    TheftLocation: $TheftLocation
                    WhenVehicleLastSeen: $TheftWhenVehicleLastSeen
                    HowVehicleHoused: $TheftHowVehicleHoused
                    WasVehicleLocked: $TheftWasVehicleLocked
                    WhereWereKeys: $TheftWhereWereKeys
                    WhereAreKeysNow: $TheftWhereAreKeysNow
                    OnlyPartsOfVehicleStolen: $TheftOnlyPartsOfVehicleStolen
                    OnlyPartsOfVehicleStolen_Yes_Describe: $TheftOnlyPartsOfVehicleStolen_Yes_Describe
                    HasTheftReportedToPolice: $TheftHasTheftReportedToPolice
                    PoliceOfficerName: $TheftPoliceOfficerName
                    PoliceOfficerNumber: $TheftPoliceOfficerNumber
                    PoliceReportNumber: $TheftPoliceReportNumber
                    WhenPoliceNotified: $TheftWhenPoliceNotified
                    AnySuspicionsOffender: $TheftAnySuspicionsOffender
                    AnySuspicionsOffender_Yes_Describe: $TheftAnySuspicionsOffender_Yes_Describe
                    AnySuspicionsOffender_Yes_Relationship: $TheftAnySuspicionsOffender_Yes_Relationship
                    SketchPlans: $SketchPlans
                    HasVehicleRecovered: $RecoveryVehicleRecovered
                    RecoveredDate: $RecoveryDate
                    RecoveredTime: $RecoveryTime
                    RecoveredLocation: $RecoveryRecoveredLocation
                    VehicleCurrentLocation: $RecoveryVehicleCurrentLocation
                    WhoRecovered: $RecoveryWhoRecovered
                    WasTowingCompanyUsed: $RecoveryWasTowingCompanyUsed
                    WasTowingCompanyUsed_Yes_Details: $RecoveryWasTowingCompanyUsed_Yes_Details
                    DescribeStolenDamangedParts: $RecoveryDescribeStolenDamangedParts
                    HavePreferredRepairer: $RecoveryHavePreferredRepairer
                    PreferredRepairerName: $RecoveryPreferredRepairerName
                    PreferredRepairerAddress: $RecoveryPreferredRepairerAddress
                    PreferredRepairerPhone: $RecoveryPreferredRepairerPhone
                    ObtainedRepairQuote: $RecoveryObtainedRepairQuote
                    ObtainedRepairQuote_Yes_Estimate: $RecoveryObtainedRepairQuote_Yes_Estimate
                    IsPolicyHolderBestContact: $RecoveryIsPolicyHolderBestContact
                    BestContactName: $RecoveryBestContactName
                    BestContactEmail: $RecoveryBestContactEmail
                    BestContactPhone: $RecoveryBestContactPhone
                    RelevantInformation: $DeclarationRelevantInformation

                    DisputeTerm: $DeclarationDisputeTerm
                    FurtherInformation: $FurtherInformation
                    ReleaseDocsToPolice: $DeclarationReleaseDocsToPolice
                    AuthorityToDisclosurePersonalInfoFromOtherParties: $DeclarationDiscloseOtherParties
                    LumleyReleasingToOtherPartiesPersonalInformation: $DeclarationLumleyPersonalInformation
                    AgentObtainPersonalInformationFromOtherParties: $DeclarationAgentObtainOtherParties
                ) {
                    PolicyHolder {
                        FullName
                    }
                }
            }
        `,
        {
            onCompleted: data => handleSuccess(data),
            onError: errors => handleError(errors),
        }
    );

    const [fileUpload] = useMutation(gql`
        mutation FileUpload($file: String!) {
            FileUpload(file: $file)
        }
    `);

    useEffect(() => {
        if (localData && localData.step && localData.step < step) {
            setStep(localData.step);
        }
    });

    const saveLocalData = data => {
        window.localStorage.setItem(
            'theft',
            JSON.stringify({
                ...localData,
                ...data,
                step: step + 1,
            })
        );
    };

    const saveContinue = () => {
        setStep(step + 1);
        window.scrollTo(0, 0);
    };

    const saveFinish = () => {
        window.scrollTo(0, 0);
        setIsSubmitting(true);

        const { policy, vehicle, driver, theft, recovery, declaration } = JSON.parse(
            window.localStorage.getItem('theft')
        );

        const ids = JSON.parse(window.localStorage.getItem('ids'));

        submitForm({
            onError: error => handleError(error),
            onCompleted: data => handleSuccess(data),
            variables: {
                PolicyHolderFullName: policy.fullName,
                PolicyHolderEmail: policy.email,
                PolicyHolderPhone: policy.phone,
                PolicyClientReference: policy.clientReference,
                PolicyHolderAddressLine1: policy.addressLine1,
                PolicyHolderAddressLine2: policy.addressLine2,
                PolicyHolderSuburb: policy.suburb,
                PolicyHolderCity: policy.city,
                PolicyHolderPostcode: policy.postCode,
                // PolicyVehiclePermanentResidence: policy.vehiclePermanentResidence,

                InsuredVehicleMake: vehicle.make,
                InsuredVehicleModel: vehicle.model,
                InsuredVehicleManufactureYear: Number(vehicle.manufactureYear),
                InsuredVehicleCondition: vehicle.conditionNote.conditionNoteRequired,
                InsuredVehicleCondition_Yes_Describe: vehicle.conditionNote.conditionNote,
                InsuredVehicleRegistrationOrVIN: vehicle.registration,
                InsuredVehicleModifications: vehicle.modifications.modificationsRequired,
                InsuredVehicleModifications_Details: vehicle.modifications.modifications,
                // InsuredVehicleAnyLicenceEndorsementsRequired:
                //     vehicle.endorsements.endorsementsRequired,
                // InsuredVehicleAnyLicenceEndorsementsRequired_Yes_Describe:
                //     vehicle.endorsements.endorsements,
                InsuredVehicleSoleOwner: vehicle.owner,
                // InsuredVehicleSoleOwner_No_ContactName: vehicle.owner.interestedParties,
                // InsuredVehicleSoleOwner_No_ContactDetails: vehicle.owner.interestedPartiesContact,
                InsuredVehicleHaveFinancialInterest: vehicle.finance.financeOwing,
                InsuredVehicleHaveFinancialInterest_Company: vehicle.finance.financeCompany,
                InsuredVehicleHaveFinancialInterest_OutstandingAmount:
                    vehicle.finance.financeOutstanding,

                DriverPolicyHolderInCharge: driver.insuredInCharge,
                DriverFullName: driver.fullName,
                DriverBirthDate: driver.dob,
                DriverAddressLine1: driver.addressLine1,
                DriverAddressLine2: driver.addressLine2,
                DriverSuburb: driver.suburb,
                DriverCity: driver.city,
                DriverPostcode: driver.postcode,
                DriverEmail: driver.email,
                DriverPhone: driver.phone,
                DriverRelationship: driver.relationship,
                DriverOwnersConsent: driver.consent,
                DriverInsuranceRefusedIn5Years:
                    driver.insuranceRefused && driver.insuranceRefused.hadInsuranceRefused,
                DriverInsuranceRefusedIn5Years_Yes_Describe:
                    driver.insuranceRefused && driver.insuranceRefused.insuranceRefusedDetails,
                DriverInvolvedAccidentIn5Years: driver.accident && driver.accident.hadAccident,
                DriverInvolvedAccidentIn5Years_Yes_Describe:
                    driver.accident && driver.accident.accidentDetails,
                DriverInfringementIn5Years: driver.convicted && driver.convicted.beenConvicted,
                DriverInfringementIn5Years_Yes_Describe:
                    driver.convicted && driver.convicted.convictedDetails,

                TheftDate: theft.date,
                TheftTime: theft.time,
                TheftWhenOccured: theft.discovery,
                TheftLocation: theft.address,
                TheftWhenVehicleLastSeen: theft.lastSeen,
                TheftHowVehicleHoused: theft.housed.housedValue,
                TheftWasVehicleLocked: theft.locked,
                TheftWhereWereKeys: theft.keys,
                TheftWhereAreKeysNow: theft.keysNow,
                TheftOnlyPartsOfVehicleStolen: theft.parts.onlyParts,
                TheftOnlyPartsOfVehicleStolen_Yes_Describe: theft.parts.details,
                TheftHasTheftReportedToPolice: theft.police.reported,
                TheftPoliceOfficerName: theft.police.officerName,
                TheftPoliceOfficerNumber: theft.police.officerNumber,
                TheftPoliceReportNumber: theft.police.reportNumber,
                TheftWhenPoliceNotified: theft.police.notified,
                TheftAnySuspicionsOffender: theft.suspicions.haveOffenderSuspicions,
                TheftAnySuspicionsOffender_Yes_Describe: theft.suspicions.details,
                TheftAnySuspicionsOffender_Yes_Relationship: theft.suspicions.offenderRelationship,
                SketchPlans: ids,

                RecoveryVehicleRecovered: recovery.recovery.recovered,
                RecoveryDate: recovery.recovery.date,
                RecoveryTime: recovery.recovery.time,
                RecoveryRecoveredLocation: recovery.location,
                RecoveryRelevantInfo: recovery.recovery.relevantInfo,
                RecoveryVehicleCurrentLocation: recovery.recovery.locationFound,
                RecoveryWhoRecovered: recovery.recovery.finder,
                RecoveryWasTowingCompanyUsed:
                    recovery.recovery.towed && recovery.recovery.towed.wasTowed,
                RecoveryWasTowingCompanyUsed_Yes_Details:
                    recovery.recovery.towed && recovery.recovery.towed.details,
                RecoveryDescribeStolenDamangedParts: recovery.recovery.stolenDamagedParts,
                RecoveryHavePreferredRepairer:
                    recovery.recovery.preferredRepairer &&
                    recovery.recovery.preferredRepairer.hasPreferredRepairer,
                RecoveryPreferredRepairerName:
                    recovery.recovery.preferredRepairer && recovery.recovery.preferredRepairer.name,
                RecoveryPreferredRepairerAddress:
                    recovery.recovery.preferredRepairer &&
                    recovery.recovery.preferredRepairer.address,
                RecoveryPreferredRepairerPhone:
                    recovery.recovery.preferredRepairer &&
                    recovery.recovery.preferredRepairer.phone,
                RecoveryObtainedRepairQuote:
                    recovery.recovery.repairEstimate &&
                    recovery.recovery.repairEstimate.obtainedEstimate,
                RecoveryObtainedRepairQuote_Yes_Estimate:
                    recovery.recovery.repairEstimate &&
                    recovery.recovery.repairEstimate.estimateTotal,
                RecoveryIsPolicyHolderBestContact:
                    recovery.recovery.bestContact && recovery.recovery.bestContact.isPolicyHolder,
                RecoveryBestContactName:
                    recovery.recovery.bestContact && recovery.recovery.bestContact.name,
                RecoveryBestContactEmail:
                    recovery.recovery.bestContact && recovery.recovery.bestContact.email,
                RecoveryBestContactPhone:
                    recovery.recovery.bestContact && recovery.recovery.bestContact.phone,

                DeclarationRelevantInformation:
                    declaration.additionalInformation.hasAdditionalInformation &&
                    declaration.additionalInformation.details,

                DeclarationDisputeTerm: declaration.paymentDispute,
                FurtherInformation: declaration.furtherInformation,
                DeclarationReleaseDocsToPolice: declaration.authorisation1,
                DeclarationDiscloseOtherParties: declaration.authorisation2,
                DeclarationLumleyPersonalInformation: declaration.authorisation3,
                DeclarationAgentObtainOtherParties: declaration.authorisation4,
            },
        });
    };

    const transformErrors = errors =>
        errors.map(error => {
            /* eslint-disable */
            if (error.name === 'required') error.message = 'Required';
            if (error.name === 'enum') error.message = 'More information required';
            if (error.name === 'oneOf') error.message = '';

            if (error.name === 'format' && error.params.format === 'email') {
                error.message = 'Invalid email address';
            }
            /* eslint-enable */

            return error;
        });

    const renderFormStep = () => {
        switch (step) {
            case 0:
                return (
                    <Policy
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 1:
                return (
                    <Vehicle
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 2:
                return (
                    <Driver
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 3:
                return (
                    <Theft
                        saveContinue={saveContinue}
                        fileUpload={fileUpload}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 4:
                return (
                    <Recovery
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 5:
                if (isSubmitting) {
                    return (
                        <div>
                            <h1>We are processing your request</h1>
                            <p>
                                We appreciate your your time and in a moment you will be notified
                                with a a confirmation email.
                            </p>
                        </div>
                    );
                }

                return (
                    <Declaration
                        saveContinue={saveFinish}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            default:
                return <div>Form complete</div>;
        }
    };

    return (
        <div className="l-multi-step-form">
            <StepProgress
                steps={steps}
                currentStep={step}
                setStep={targetStep => () => setStep(targetStep)}
            />
            <div className="l-multi-step-form__wrapper">
                {formError && (
                    <div>
                        <strong>Error:</strong> {formError}
                    </div>
                )}
                {renderFormStep()}
                <div className="fineprint">
                    All claims are subject to the underwriter's acceptance of cover.
                    <br />
                    See our&nbsp;
                    <a rel="noopener noreferrer" target="_blank" href="/privacy-policy/">
                        policy wording
                    </a>
                    &nbsp; for more information.
                </div>
                {/* {step > 0 && <button type="button" onClick={back}>Go Back</button>} */}
            </div>
            <StepProgress
                steps={steps}
                currentStep={step}
                setStep={targetStep => () => setStep(targetStep)}
            />
        </div>
    );
};

export default TheftForm;

export default {
    driver: {
        insuredInCharge: {
            classNames: 'field-parent',
            'ui:title':
                'Was the insured person named in section 1 in charge of the vehicle at the time of the accident?*',
            'ui:widget': 'radio',
        },

        fullName: {
            'ui:title': "What is the person's full name?*",
        },

        dob: {
            classNames: 'field-date',
            'ui:title': 'Date of Birth*',
            'ui:widget': 'alt-date',
            'ui:options': {
                hideNowButton: true,
                hideClearButton: true,
            },
        },
        email: {
            'ui:title': 'Email address',
        },
        phone: {
            'ui:title': 'Phone number*',
        },
        addressLine1: {
            'ui:title': 'Address line 1',
        },
        addressLine2: {
            'ui:title': 'Address line 2',
        },
        suburb: {
            'ui:title': 'Suburb',
        },
        city: {
            'ui:title': 'City',
        },
        postcode: {
            'ui:title': 'Postcode',
        },
        consent: {
            'ui:title': 'Was the vehicle being driven with the owners consent?*',
            'ui:widget': 'radio',
        },
        insuranceRefused: {
            hadInsuranceRefused: {
                classNames: 'field-parent',
                'ui:title': 'Has the driver had insurance refused in the last 5 years?*',
                'ui:widget': 'radio',
            },
            insuranceRefusedDetails: {
                'ui:title': 'Please provide details*',
            },
        },
        accident: {
            hadAccident: {
                classNames: 'field-parent',
                'ui:title': 'Has the driver been involved in an accident in the last 5 years?*',
                'ui:widget': 'radio',
            },
            accidentDetails: {
                'ui:title': 'Please provide details*',
            },
        },
        convicted: {
            beenConvicted: {
                classNames: 'field-parent',
                'ui:title':
                    'Has the driver been convicted of a driving offence or infringement in the last 5 years?*',
                'ui:widget': 'radio',
            },
            convictedDetails: {
                'ui:title': 'Please provide details*',
            },
        },
    },
    licence: {
        stepHeading: {
            classNames: 'field-step-heading',
            'ui:title': '3.2. Person in charge/driver licence details',
            // 'ui:description': '3.2.',
        },
        number: {
            'ui:title': 'What is the drivers licence number?*',
        },
        appropriateClass: {
            'ui:title':
                'Did the driver have the appropriate classes of licence to drive this vehicle?*',
            'ui:widget': 'radio',
        },
        appropriateEndorsements: {
            'ui:title': 'Did the driver have the appropriate endorsements to drive this vehicle?*',
            'ui:widget': 'radio',
        },
        conditions: {
            'ui:title': 'Are there any conditions on the licence?*',
            'ui:widget': 'radio',
        },
        type: {
            classNames: 'field-select',
            'ui:title': 'What type of licence is held?*',
            'ui:placeholder': 'Pick the licence type',
            'ui:widget': 'select',
        },
        country: {
            classNames: 'field-select',
            'ui:title': 'What is the country of issue?*',
            'ui:placeholder': 'Pick the country of issue',
            'ui:widget': 'select',
        },
    },
};

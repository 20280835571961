/* global $ */

$(document).on('click', '#admin-toggle', function(evt) {
    evt.stopImmediatePropagation();
    $('.admin-dropdown').toggleClass('open');
});

$(document).ready(function() {
    if ($('#Password_Password')) {
        $('#Password_Password').attr('placeholder', 'Choose your password*');
        $('#Password_ConfirmPassword').attr('placeholder', 'Confirm your password*');
    }

    if ($('.testimonial-slider')) {
        $('.testimonial-slider').slick({
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
            responsive: [
                {
                    breakpoint: 760,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
    }

    $('.veggie-burger-toggler')
        .unbind('click')
        .click(function() {
            $(this).toggleClass('menu-open');
            $('.menu').toggleClass('open');
        });

    $('.menu-list li .mobile-togler')
        .unbind('click')
        .click(function() {
            $(this)
                .parent()
                .toggleClass('open');
        });

    $('.mobile-tabs button').click(function() {
        const activeSection = this.getAttribute('data-tab');

        $('.mobile-tabs button').removeClass('active');
        $(this).addClass('active');

        $('.column').removeClass('active');
        $(`.${activeSection}`).addClass('active');
    });

    if ($('#dealer-table') !== undefined) {
        $.fn.dataTable.moment('DD/MM/YYYY');
        $('#dealer-table').DataTable({
            info: false,
            // columnDefs: [ { type: 'date', 'targets': [4] } ],
            aaSorting: [[4, 'desc']],
            pageLength: 5,
            oLanguage: {
                sEmptyTable: 'You have no quotes. <a href="/dealer-quote">Create a new quote</a>',
            },
            language: {
                search: '<i class="fa fa-filter" aria-hidden="true"></i>',
                searchPlaceholder: 'Search',
                paginate: {
                    next: '&#x203A;',
                    previous: '&#x2039;',
                },
            },
        });
    }

    $('.deleteQuote').click(function() {
        const confirm = window.confirm('Are you sure you wish to delete this quote?');
        if (!confirm) {
            return;
        }
        const data = { id: $(this).attr('data-id') };
        $.post('/api/dealer/delete', data, function(response) {
            if (response) {
                console.log(response);
                window.location.reload();
            } else {
                console.log('We have a problem');
            }
        });
    });

    // $('.viewQuote').click(function() {
    //
    //     console.log("THIS IS NOT A SOLUTION!!");
    //
    //     //TODO this needs link to to a new page that doesnt load an iframe!!!
    //
    //     const data = { id: $(this).attr('data-id') };
    //     $.post('/api/dealer/viewquote', data, function(response) {
    //         if (response) {
    //             if (response === "" || response === undefined) {
    //                 alert("Falied to open PDF.");
    //             } else { //For IE using atob convert base64 encoded data to byte array
    //                 console.log(response);
    //                 // window.location = response;
    //                 var win = window.open("TEST", '_blank');
    //                 win.document.write("<iframe width='100%' height='100%' src=" + response + "><\/iframe>");
    //             }
    //         }else {
    //             console.log("We have a problem");
    //         }
    //     });
    // });

    // -------------------------------- FAQS -------------------------
    function handleSearchFilter(term) {
        /* eslint-disable no-use-before-define */
        $.each($('.faqs-item'), function() {
            const title = $(this)
                .find('h4')
                .text()
                .toLowerCase();

            /* eslint-disable no-use-before-define */
            if (title.indexOf(term.toLowerCase()) === -1) {
                $(this).addClass('hide');
            } else {
                $(this).removeClass('hide');
            }
        });
    }

    /* eslint-disable no-use-before-define */
    if ($('#faqs-element__content')) {
        $('.faqs-item')
            .unbind('click')
            .click(function() {
                $(this).toggleClass('open');
            });

        /* eslint-disable no-use-before-define */
        $('#faqs-search').submit(function(e) {
            e.preventDefault();
            handleSearchFilter($('#faqs-search-field').val());
        });

        /* eslint-disable no-use-before-define */
        $('#faqs-search-field').on('change paste keyup', function(e) {
            const dInput = this.value;

            if (!dInput.length) {
                $('.faqs-item').removeClass('hide');
                return;
            }

            const k = e.keyCode || e.which;
            if (k === 13) {
                e.preventDefault();
            }

            handleSearchFilter(dInput);
        });
    }
});

$(window).on('load resize orientationchange', () => {
    if ($(window).width() > 760) {
        if ($('.quick-note-tiles-with-slider').hasClass('slick-initialized')) {
            $('.quick-note-tiles-with-slider').slick('unslick');
        }
    } else if (!$('.quick-note-tiles-with-slider').hasClass('slick-initialized')) {
        $('.quick-note-tiles-with-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            mobileFirst: true,
        });
    }
});

// import './modules/image';

// import Animation from './modules/animation';
// import Accordion from './modules/accordion';
// import Carousel from './modules/carousel';
// import DatePicker from './modules/datepicker';
// import Form from './modules/form';
// import Header from './modules/header';
// import MobileMenu from './modules/mobile-menu';
// import PartnerFilters from './modules/partners';
// import ReCaptcha from './modules/recaptcha';
import FormHandler from './modules/formHandler';
// import Tabs from './modules/tabs';
import Forms from './react/app';
// import Scroll from './utilities/scroll';

document.addEventListener('DOMContentLoaded', () => {
    new Forms(); // eslint-disable-line

    //   Header.bindEvents();
    //   MobileMenu.bindEvents();
    //   Animation.bindEvents();
    //   Carousel.bindEvents();
    //   Scroll.bindEvents();
    //   Accordion.bindEvents();
    //   Form.bindEvents();
    //   PartnerFilters.bindEvents();
    //   Tabs.bindEvents();
    //   DatePicker.bindEvents();
    // ReCaptcha.bindEvents();
    FormHandler.bindEvents();
});

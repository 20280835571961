const uiSchema = {
    date: {
        'ui:title': 'What date did the theft happen?*',
        classNames: 'field-date',
        'ui:widget': 'alt-date',
        'ui:options': {
            hideNowButton: true,
            hideClearButton: true,
            yearsRange: [new Date().getFullYear() - 2, new Date().getFullYear()],
        },
    },
    time: {
        'ui:title': 'What time did the theft happen?*',
    },
    discovery: {
        'ui:title': 'When did you discover the theft had occurred?',
    },
    address: {
        'ui:title': 'From what address was the theft? (street name, suburb, town)*',
    },
    lastSeen: {
        'ui:title': 'When was the vehicle last seen?*',
    },
    housed: {
        housedValue: {
            classNames: 'field-select',
            'ui:widget': 'select',
            'ui:placeholder': 'Please select*',
            'ui:title': 'How was the vehicle housed?',
        },
        details: {
            'ui:title': 'Please describe*',
        },
    },
    locked: {
        'ui:title': 'Was the vehicle locked?*',
        'ui:widget': 'radio',
    },
    keys: {
        'ui:title': 'Where were the keys?*',
    },
    keysNow: {
        'ui:title': 'Where are all the sets of keys now?*',
    },
    parts: {
        onlyParts: {
            classNames: 'field-parent',
            'ui:title': 'Was the vehicle stolen or parts only?',
            'ui:widget': 'radio',
        },
        details: {
            'ui:title': 'Please give details',
        },
    },
    police: {
        reported: {
            classNames: 'field-parent',
            'ui:title': 'Has the theft been reported to the Police?*',
            'ui:widget': 'radio',
        },
        officerName: {
            'ui:title': 'Police officer name',
        },
        officerNumber: {
            'ui:title': 'Police officer number',
        },
        reportNumber: {
            'ui:title': 'Police report number*',
        },
        notified: {
            'ui:title': 'When were the police notified?*',
            classNames: 'field-date',
            'ui:widget': 'alt-date',
            'ui:options': {
                hideNowButton: true,
                hideClearButton: true,
                yearsRange: [new Date().getFullYear() - 2, new Date().getFullYear()],
            },
        },
    },

    suspicions: {
        haveOffenderSuspicions: {
            classNames: 'field-parent',
            'ui:title': 'Have you any suspicions who the offender was?',
            'ui:widget': 'radio',
        },
        details: {
            'ui:title': 'Please give details',
        },
        offenderRelationship: {
            'ui:title': 'What is their relationship to the owner? (if any)',
        },
    },

    sketches2: {
        stepHeading: {
            classNames: 'field-step-heading',
            'ui:title': 'Sketch plan of the incident',
            'ui:description': '4.2.',
        },
        stepDescription: {
            classNames: 'field-step-description',
            'ui:title':
                'Please draw and upload a sketch of the Incident. Indicate street names, direction of vehicles and any other important information.',
        },
        listOfFiles: {
            classNames: 'field-uploads',
            items: {
                file: {
                    classNames: 'field-upload',
                    'ui:title': 'Upload file (JPG, PNG OR PDF)',
                    'ui:options': {
                        accept: ['.pdf', '.jpg', '.jpeg', '.png'],
                    },
                },
            },
        },
    },
};

export default uiSchema;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';
import convert from 'xml-js';

import schema from './schema';
import uiSchema from './uiSchema';

const Vehicle = ({ saveContinue, saveLocalData, transformErrors, quoteId }) => {
    const localData = JSON.parse(window.localStorage.getItem(`dealerQuote-${quoteId}`));
    const formData = localData && localData.details ? localData.details : {};
    let fetchedVin = '';

    function debounce(func, wait) {
        let timeout;
        return function(...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }

    const handleKeyDown = event => {
        if (event.keyCode === 13) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown.bind(this));
    }, []);

    useEffect(() => {
        if (formData?.quoteInformation?.modificationDetails?.modificationOverAGrand) {
            if (
                formData.quoteInformation.modificationDetails.modificationOverAGrand.indexOf(
                    'Other'
                ) !== -1
            ) {
                document.querySelector('.modificationOverAGrandNote').classList.remove('hide');
            } else {
                document.querySelector('.modificationOverAGrandNote').classList.add('hide');
            }

            if (formData.quoteInformation.modificationDetails.modificationOverAGrand.length) {
                document
                    .getElementById(
                        'root_quoteInformation_modificationDetails_modificationOverAGrand__description'
                    )
                    .classList.remove('hide');
            } else {
                document
                    .getElementById(
                        'root_quoteInformation_modificationDetails_modificationOverAGrand__description'
                    )
                    .classList.add('hide');
            }
        }
    }, []);

    const isNumeric = value => {
        return /^\d+$/.test(value);
    };

    const validateAutoFillFields = () => {
        let valid = true;
        const makeField = document.getElementById(`root_vehicleInformation_informationType_make`);
        const modelField = document.getElementById(`root_vehicleInformation_informationType_model`);
        const yearField = document.getElementById(`root_vehicleInformation_informationType_year`);
        const regField = document.getElementById(
            `root_vehicleInformation_informationType_registration`
        );
        const typeField = document.getElementById(`root_vehicleInformation_informationType_type`);
        const ccRatingField = document.getElementById(
            `root_vehicleInformation_informationType_ccRating`
        );

        if (!makeField.value) {
            document
                .querySelector('.auto-field.make')
                .classList.add(...['field-error', 'has-error', 'has-danger']);
            valid = false;
        } else {
            document
                .querySelector('.auto-field.make')
                .classList.remove(...['field-error', 'has-error', 'has-danger']);
        }

        if (!modelField.value) {
            document
                .querySelector('.auto-field.model')
                .classList.add(...['field-error', 'has-error', 'has-danger']);

            valid = false;
        } else {
            document
                .querySelector('.auto-field.model')
                .classList.remove(...['field-error', 'has-error', 'has-danger']);
        }

        // eslint-disable-next-line radix
        if (!yearField.value || !isNumeric(parseInt(yearField.value))) {
            document
                .querySelector('.auto-field.year')
                .classList.add(...['field-error', 'has-error', 'has-danger']);
            valid = false;
        } else {
            document
                .querySelector('.auto-field.year')
                .classList.remove(...['field-error', 'has-error', 'has-danger']);
        }

        // if (!regField.value) {
        //     document
        //         .querySelector('.auto-field.reg')
        //         .classList.add(...['field-error', 'has-error', 'has-danger']);

        //     valid = false;
        // } else {
        //     document
        //         .querySelector('.auto-field.reg')
        //         .classList.remove(...['field-error', 'has-error', 'has-danger']);
        // }

        if (!typeField.value) {
            document
                .querySelector('.auto-field.type')
                .classList.add(...['field-error', 'has-error', 'has-danger']);

            valid = false;
        } else {
            document
                .querySelector('.auto-field.type')
                .classList.remove(...['field-error', 'has-error', 'has-danger']);
        }

        // eslint-disable-next-line radix
        if (!ccRatingField.value || !isNumeric(parseInt(ccRatingField.value))) {
            document
                .querySelector('.auto-field.cc')
                .classList.add(...['field-error', 'has-error', 'has-danger']);

            valid = false;
        } else {
            document
                .querySelector('.auto-field.cc')
                .classList.remove(...['field-error', 'has-error', 'has-danger']);
        }

        return valid;
    };

    const onSubmit = data => {
        const details = data.formData;

        details.vehicleInformation.informationType.make = document.getElementById(
            `root_vehicleInformation_informationType_make`
        ).value;

        details.vehicleInformation.informationType.model = document.getElementById(
            `root_vehicleInformation_informationType_model`
        ).value;

        details.vehicleInformation.informationType.year = document.getElementById(
            `root_vehicleInformation_informationType_year`
        ).value;

        details.vehicleInformation.informationType.registration = document.getElementById(
            `root_vehicleInformation_informationType_registration`
        ).value;

        details.vehicleInformation.informationType.type = document.getElementById(
            `root_vehicleInformation_informationType_type`
        ).value;

        details.vehicleInformation.informationType.ccRating = document.getElementById(
            `root_vehicleInformation_informationType_ccRating`
        ).value;

        window.dataLayer.push({
            event: 'dealerQuoteSubmission',
            step: 1,
        });

        if (!validateAutoFillFields()) return;

        saveLocalData({ details });

        saveContinue();
    };

    const handleExtraModificationUIToggle = mods => {
        if (!mods) {
            document
                .getElementById(
                    'root_quoteInformation_modificationDetails_modificationOverAGrand__description'
                )
                .classList.add('hide');
            return;
        }

        document
            .getElementById(
                'root_quoteInformation_modificationDetails_modificationOverAGrand__description'
            )
            .classList.remove('hide');

        if (mods.indexOf('Other') !== -1) {
            document.querySelector('.modificationOverAGrandNote').classList.remove('hide');
        } else {
            document.querySelector('.modificationOverAGrandNote').classList.add('hide');
        }

        if (mods.length) {
            document
                .getElementById(
                    'root_quoteInformation_modificationDetails_modificationOverAGrand__description'
                )
                .classList.remove('hide');
        } else {
            document
                .getElementById(
                    'root_quoteInformation_modificationDetails_modificationOverAGrand__description'
                )
                .classList.add('hide');
        }
    };

    const fetchCarData = async vin => {
        try {
            const response = await fetch(
                `https://carjam.co.nz/api/car/?key=32085C8102AEC1F33D9C83F52BBA9A28846D70D8&plate=${vin}`
            );

            let data = await response.text();
            data = convert.xml2json(data);
            data = JSON.parse(data);
            data = data.elements
                ? data.elements[0]?.elements?.find(e => e.name === 'idh')
                : { elements: [] };

            const vehicle = data?.elements?.find(e => e.name === 'vehicle').elements ?? [];
            return vehicle;
        } catch (e) {
            console.log('ERROR: ', e);
        }

        return null;
    };

    const populateCarData = async vin => {
        if (fetchedVin === vin) {
            return;
        }

        const carData = await fetchCarData(vin);

        const make = carData?.find(e => e.name === 'make')?.elements[0].text;
        const model = carData?.find(e => e.name === 'model')?.elements[0].text;
        const yearOfManufacture = carData?.find(e => e.name === 'year_of_manufacture')?.elements[0]
            .text;
        const carVin = carData?.find(e => e.name === 'vin')?.elements[0].text;
        const type = carData?.find(e => e.name === 'vehicle_type')?.elements[0].text;
        const ccRating = carData?.find(e => e.name === 'cc_rating')?.elements[0].text;
        let regPlate = carData?.find(e => e.name === 'plates')?.elements;
        regPlate = regPlate?.find(e => e.name === 'registration_plate')?.elements[0]?.text;

        if (make) {
            document.getElementById(`root_vehicleInformation_informationType_make`).value = make;
        }

        if (model) {
            document.getElementById(`root_vehicleInformation_informationType_model`).value = model;
        }
        if (yearOfManufacture)
            document.getElementById(
                `root_vehicleInformation_informationType_year`
            ).value = yearOfManufacture;
        if (regPlate)
            document.getElementById(
                `root_vehicleInformation_informationType_registration`
            ).value = regPlate;
        if (type)
            document.getElementById(`root_vehicleInformation_informationType_type`).value = type;
        if (ccRating)
            document.getElementById(
                `root_vehicleInformation_informationType_ccRating`
            ).value = ccRating;

        fetchedVin = vin;
    };

    return (
        <>
            {/* <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    2. Vehicle Details
                </h2>
            </div> */}
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => {
                    validateAutoFillFields();
                    window.scrollTo(0, 0);
                }}
                onChange={debounce(value => {
                    const vin = value.formData.vehicleInformation.informationType.identityNumber;
                    if (vin) {
                        populateCarData(vin);
                    }

                    handleExtraModificationUIToggle(
                        value.formData.quoteInformation.modificationDetails.modificationOverAGrand
                    );
                }, 250)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Vehicle.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Vehicle.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
    quoteId: PropTypes.string.isRequired,
};

export default Vehicle;

import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';

const Policy = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('accident'));
    const formData = localData && localData.policy ? localData.policy : {};

    const onSubmit = data => {
        const policy = data.formData;

        window.dataLayer.push({
            event: 'accidentClaimSubmission',
            step: 1,
            name: policy.fullName,
            email: policy.email,
            phone: policy.phone,
            address: `${policy.addressLine1} ${policy.addressLine2}`,
            suburb: policy.suburb,
            city: policy.city,
            postcode: policy.postCode,
            insurance_additional_cover: policy.otherInsurance.hasOtherInsurance,
        });

        saveLocalData({ policy });
        saveContinue();
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">1. Policy Holder</h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Policy.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Policy.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Policy;

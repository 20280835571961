import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';

import schema from './schema';
import uiSchema from './uiSchema';

const Damage = ({ saveContinue, saveLocalData, transformErrors }) => {
    const localData = JSON.parse(window.localStorage.getItem('accident'));
    const formData = localData && localData.damage ? localData.damage : {};

    const onSubmit = data => {
        const damage = data.formData;

        window.dataLayer.push({
            event: 'accidentClaimSubmission',
            step: 5,
            damage_best_contact_policy_holder: damage.bestContact.isPolicyHolder,
            damage_preferred_repairer: damage.preferredRepairer.hasPreferredRepairer,
            damage_obtained_quote:
                damage.preferredRepairer.quote && damage.preferredRepairer.quote.obtainedQuote,
            damage_obtained_quote_estimate:
                damage.preferredRepairer.quote && damage.preferredRepairer.quote.quoteEstimate,
            damage_vehicle_location: damage.vehicleLocation,
            damage_with_vehicle: damage.nonVehicle.nonVehiclePropertyDamaged,
            damage_property_damage: damage.nonVehicle.nonVehiclePropertyDamageDetails,
            damage_injured: damage.injuries.anyInjuries,
            damage_emergency_services:
                damage.injuries.emergencyServices &&
                damage.injuries.emergencyServices.emergencyServicesAttended,
            damage_hospitalisation: damage.injuries.hospital,
        });

        saveLocalData({ damage });
        saveContinue();
    };

    return (
        <>
            <div className="l-multi-step-form__heading">
                <h2 className="c-heading c-heading--h3 u-color--secondary-500">
                    5. Recovery and damage
                </h2>
            </div>
            <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={formData}
                noHtml5Validate
                showErrorList={false}
                transformErrors={transformErrors}
                onSubmit={data => onSubmit(data)}
                onError={() => window.scrollTo(0, 0)}
            >
                <div className="actions">
                    <button className="c-button c-button--primary" type="submit">
                        <span className="c-button__text">Save and continue</span>
                    </button>
                </div>
            </Form>
        </>
    );
};

Damage.defaultProps = {
    saveContinue: () => {},
    saveLocalData: () => {},
    transformErrors: () => {},
};

Damage.propTypes = {
    saveContinue: PropTypes.func,
    saveLocalData: PropTypes.func,
    transformErrors: PropTypes.func,
};

export default Damage;

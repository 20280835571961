export default {
    insuredInCharge: {
        classNames: 'field-parent',
        'ui:title':
            'Was the insured named in section 1 in charge of the vehicle at the time of the theft?*',
        'ui:widget': 'radio',
    },
    fullName: {
        'ui:title': "What is the driver's full name?*",
    },
    dob: {
        'ui:title': 'What is the driver’s date of birth?*',
        classNames: 'field-date',
        'ui:widget': 'alt-date',
        'ui:options': {
            hideNowButton: true,
            hideClearButton: true,
        },
    },
    email: {
        'ui:title': 'What is the driver’s email address?',
    },
    phone: {
        'ui:title': 'What is the driver’s phone number?*',
    },
    addressLine1: {
        'ui:title': 'What is the driver’s address?',
    },
    addressLine2: {
        'ui:title': 'Address line 2',
    },
    suburb: {
        'ui:title': 'Suburb',
    },
    city: {
        'ui:title': 'City',
    },
    postcode: {
        'ui:title': 'Postcode',
    },
    relationship: {
        'ui:title': "What is the driver's relationship to the insured?",
    },
    consent: {
        'ui:title': 'Was the vehicle being used with the owners consent?*',
        'ui:widget': 'radio',
    },
    insuranceRefused: {
        hadInsuranceRefused: {
            classNames: 'field-parent',
            'ui:title': 'Has the driver had insurance refused in the last 5 years?*',
            'ui:widget': 'radio',
        },
        insuranceRefusedDetails: {
            'ui:title': 'Please provide details*',
        },
    },
    accident: {
        hadAccident: {
            classNames: 'field-parent',
            'ui:title': 'Has the driver been involved in an accident in the last 5 years?*',
            'ui:widget': 'radio',
        },
        accidentDetails: {
            'ui:title': 'Please provide details*',
        },
    },
    convicted: {
        beenConvicted: {
            classNames: 'field-parent',
            'ui:title':
                'Has the driver been convicted of a driving offence or infringement in the last 5 years?*',
            'ui:widget': 'radio',
        },
        convictedDetails: {
            'ui:title': 'Please provide details*',
        },
    },
};

import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { StepProgress } from './components';

import { Owners, Vehicle, Interior, Pricing, Photos } from './steps';

const DealerQuoteForm = () => {
    const [formError, setFormError] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [step, setStep] = useState(0);

    const steps = ['Owners', 'Vehicle', 'Interior', 'Pricing', 'Photos'];

    const localData = JSON.parse(window.localStorage.getItem('valuation'));

    /* eslint-disable */
    const [submitForm, { mutationData }] = useMutation(gql`
        mutation Valuation {
            Valuation {
                ID
            }
        }
    `);
    /* eslint-enable */

    useEffect(() => {
        if (localData && localData.step && localData.step < step) {
            setStep(localData.step);
        }
    });

    // const back = () => {
    //   if (step > 0) setStep(step - 1);
    // };

    const saveLocalData = data => {
        window.localStorage.setItem(
            'valuation',
            JSON.stringify({
                ...localData,
                ...data,
                step: step + 1,
            })
        );
    };

    const saveContinue = () => {
        setStep(step + 1);
        window.scrollTo(0, 0);
    };

    const handleSuccess = () => {
        window.dataLayer.push({
            event: 'valuationClaimSubmission',
            step: 7,
        });

        // window.localStorage.removeItem('theft'); // TODO ENABLE ON LIVE
        window.location = `${window.location}?fs=success`;
    };

    const handleError = error => {
        if (error) {
            setFormError();
        }
    };

    const saveFinish = () => {
        window.scrollTo(0, 0);
        setIsSubmitting(true);

        // const {
        //   owners,
        //   vehicle,
        //   interior,
        //   pricing,
        //   photos,
        // } = JSON.parse(window.localStorage.getItem('theft'));

        submitForm({
            onError: error => handleError(error),
            onCompleted: data => handleSuccess(data),
            variables: {},
        });
    };

    const transformErrors = errors =>
        errors.map(error => {
            /* eslint-disable */
            if (error.name === 'required') error.message = 'Required';
            if (error.name === 'enum') error.message = 'More information required';
            if (error.name === 'oneOf') error.message = '';

            if (error.name === 'format' && error.params.format === 'email') {
                error.message = 'Invalid email address';
            }
            /* eslint-enable */

            return error;
        });

    const renderFormStep = () => {
        switch (step) {
            case 0:
                return (
                    <Owners
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 1:
                return (
                    <Vehicle
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 2:
                return (
                    <Interior
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 3:
                return (
                    <Pricing
                        saveContinue={saveContinue}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            case 4:
                if (isSubmitting) {
                    return (
                        <div>
                            <h1>We are processing your request</h1>
                            <p>
                                We appreciate your your time and in a moment you will be notified
                                with a a confirmation email.
                            </p>
                        </div>
                    );
                }

                return (
                    <Photos
                        saveContinue={saveFinish}
                        saveLocalData={saveLocalData}
                        transformErrors={transformErrors}
                    />
                );
            default:
                return <div>Form complete</div>;
        }
    };

    return (
        <div className="l-multi-step-form">
            <StepProgress
                steps={steps}
                currentStep={step}
                setStep={targetStep => () => setStep(targetStep)}
            />
            <div className="l-multi-step-form__wrapper">
                {formError && (
                    <div>
                        <strong>Error:</strong> {formError}
                    </div>
                )}
                {renderFormStep()}
                <div className="fineprint">
                    All claims are subject to the underwriter's acceptance of cover.
                    <br />
                    See our&nbsp;
                    <a rel="noopener noreferrer" target="_blank" href="/forms-and-policies/">
                        policy wording
                    </a>
                    &nbsp; for more information.
                </div>
                {/* {step > 0 && <button type="button" onClick={back}>Go Back</button>} */}
            </div>
            <StepProgress
                steps={steps}
                currentStep={step}
                setStep={targetStep => () => setStep(targetStep)}
            />
        </div>
    );
};

export default DealerQuoteForm;

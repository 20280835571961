export default {
    make: {
        'ui:title': 'Make',
        'ui:placeholder': 'Toyota',
    },
    model: {
        'ui:title': 'Model',
        'ui:placeholder': 'Corolla',
    },
    registration: {
        'ui:title': 'Registration Number',
        'ui:placeholder': 'ABC123',
    },
    manufactureYear: {
        classNames: 'field-select',
        'ui:widget': 'select',
        'ui:title': "What was your vehicle's year of manufacture?",
        'ui:placeholder': 'Pick the year of manufacture',
    },

    condition: {
        hasDefects: {
            'ui:title': 'At the time of the accident did the vehicle have any defects?',
            'ui:widget': 'radio',
            classNames: 'field-parent',
        },
        details: {
            'ui:title': 'Please describe them',
        },
    },
    modifications: {
        modificationsRequired: {
            classNames: 'field-parent',
            'ui:title': 'Does your vehicle have any modifications?',
            'ui:widget': 'radio',
        },
        modificationsDetails: {
            'ui:title': 'Please provide details',
        },
    },
    endorsementsRequired: {
        'ui:title': 'Are any licence endorsements required to operate this vehicle?*',
        'ui:widget': 'radio',
    },

    owner: {
        soleOwner: {
            classNames: 'field-parent',
            'ui:title': 'Are you the sole owner of the vehicle?*',
            'ui:widget': 'radio',
        },

        interestedParties: {
            'ui:title': 'What is the name of the other interested party/ies?',
        },

        interestedPartiesContact: {
            'ui:title': 'What are the contact details of the other interested party/ies?',
        },
    },

    finance: {
        financeOwing: {
            classNames: 'field-parent',
            'ui:title': 'Is there any finance owing on the vehicle?*',
            'ui:widget': 'radio',
        },

        financeCompany: {
            'ui:title': 'What is the name of the finance company?',
        },

        financeOutstanding: {
            'ui:title': 'What amount is still outstanding to the finance company?',
        },
    },
};

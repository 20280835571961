export default {
    type: 'object',
    required: ['insuredInCharge'],
    properties: {
        insuredInCharge: {
            type: 'boolean',
        },
    },
    dependencies: {
        insuredInCharge: {
            oneOf: [
                {
                    properties: {
                        insuredInCharge: {
                            enum: [true],
                        },
                    },
                },
                {
                    required: [
                        'fullName',
                        'dob',
                        'phone',
                        'consent',
                        'insuranceRefused',
                        'accident',
                        'convicted',
                    ],
                    properties: {
                        insuredInCharge: {
                            enum: [false],
                        },
                        fullName: {
                            type: 'string',
                        },
                        dob: {
                            type: 'string',
                            format: 'date',
                        },
                        email: {
                            type: 'string',
                        },
                        phone: {
                            type: 'string',
                        },
                        addressLine1: {
                            type: 'string',
                        },
                        addressLine2: {
                            type: 'string',
                        },
                        suburb: {
                            type: 'string',
                        },
                        city: {
                            type: 'string',
                        },
                        postcode: {
                            type: 'integer',
                        },
                        relationship: {
                            type: 'string',
                        },
                        consent: {
                            type: 'boolean',
                        },

                        insuranceRefused: {
                            type: 'object',
                            required: ['hadInsuranceRefused'],
                            properties: {
                                hadInsuranceRefused: {
                                    type: 'boolean',
                                },
                            },
                            dependencies: {
                                hadInsuranceRefused: {
                                    oneOf: [
                                        {
                                            properties: {
                                                hadInsuranceRefused: {
                                                    enum: [false],
                                                },
                                            },
                                        },
                                        {
                                            required: ['insuranceRefusedDetails'],
                                            properties: {
                                                hadInsuranceRefused: {
                                                    enum: [true],
                                                },
                                                insuranceRefusedDetails: {
                                                    type: 'string',
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },

                        accident: {
                            type: 'object',
                            required: ['hadAccident'],
                            properties: {
                                hadAccident: {
                                    type: 'boolean',
                                },
                            },
                            dependencies: {
                                hadAccident: {
                                    oneOf: [
                                        {
                                            properties: {
                                                hadAccident: {
                                                    enum: [false],
                                                },
                                            },
                                        },
                                        {
                                            required: ['accidentDetails'],
                                            properties: {
                                                hadAccident: {
                                                    enum: [true],
                                                },
                                                accidentDetails: {
                                                    type: 'string',
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        convicted: {
                            type: 'object',
                            required: ['beenConvicted'],
                            properties: {
                                beenConvicted: {
                                    type: 'boolean',
                                },
                            },
                            dependencies: {
                                beenConvicted: {
                                    oneOf: [
                                        {
                                            properties: {
                                                beenConvicted: {
                                                    enum: [false],
                                                },
                                            },
                                        },
                                        {
                                            required: ['convictedDetails'],
                                            properties: {
                                                beenConvicted: {
                                                    enum: [true],
                                                },
                                                convictedDetails: {
                                                    type: 'string',
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                },
            ],
        },
    },
};

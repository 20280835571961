export default {
    type: 'object',
    required: ['fullName', 'email', 'phone', 'otherInsurance'],
    properties: {
        fullName: {
            type: 'string',
        },
        email: {
            type: 'string',
            format: 'email',
        },
        phone: {
            type: 'string',
        },
        clientReference: {
            type: 'string',
        },
        addressLine1: {
            type: 'string',
        },
        addressLine2: {
            type: 'string',
        },
        suburb: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        postCode: {
            type: 'integer',
        },

        otherInsurance: {
            type: 'object',
            required: ['hasOtherInsurance'],
            properties: {
                hasOtherInsurance: {
                    type: 'boolean',
                },
            },
            dependencies: {
                hasOtherInsurance: {
                    oneOf: [
                        {
                            properties: {
                                hasOtherInsurance: {
                                    enum: [false],
                                },
                            },
                        },
                        {
                            required: ['otherInsuranceDetails'],
                            properties: {
                                hasOtherInsurance: {
                                    enum: [true],
                                },
                                otherInsuranceDetails: {
                                    type: 'string',
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
};

export default {
    fullName: {
        'ui:title': 'Full name of insured person or company*',
    },
    email: {
        'ui:title': 'Email address*',
        'ui:options': {
            inputType: 'email',
        },
    },
    phone: {
        'ui:title': 'Phone number*',
    },
    clientReference: {
        'ui:title': 'Client Reference (starting with CL)',
    },
    addressLine1: {
        'ui:title': 'Address line 1',
    },
    addressLine2: {
        'ui:title': 'Address line 2',
    },
    suburb: {
        'ui:title': 'Suburb',
    },
    city: {
        'ui:title': 'City',
    },
    postCode: {
        'ui:title': 'Postcode',
    },
    otherInsurance: {
        hasOtherInsurance: {
            classNames: 'field-parent',
            'ui:title': 'Do you have any other insurance on the vehicle or accessories?*',
            'ui:widget': 'radio',
        },
        otherInsuranceDetails: {
            'ui:title': 'Please describe them*',
        },
    },
};

import financeCompanies, { financeCompaniesWithPlaceholder } from '../../financeCompanies';

export default {
    type: 'object',
    required: ['vehicleInformation', 'quoteInformation'],
    properties: {
        vehicleInformation: {
            type: 'object',
            properties: {
                informationType: {
                    type: 'object',
                    required: ['purchasePrice'],
                    properties: {
                        identityNumber: {
                            type: 'string',
                        },
                        year: {
                            type: 'string',
                        },
                        make: {
                            type: 'string',
                        },
                        model: {
                            type: 'string',
                        },
                        registration: {
                            type: 'string',
                        },
                        type: {
                            type: 'string',
                        },
                        ccRating: {
                            type: 'string',
                        },
                        purchasePrice: {
                            type: 'integer',
                        },
                    },
                },
            },
        },
        quoteInformation: {
            type: 'object',
            required: ['storage', 'hasTurbo'],
            properties: {
                storage: {
                    type: 'object',
                    required: ['storageType'],
                    properties: {
                        storageType: {
                            type: 'string',
                            enum: ['Garage', 'Carport', 'Drive', 'Street', 'Other'],
                        },
                    },
                    dependencies: {
                        storageType: {
                            oneOf: [
                                {
                                    properties: {
                                        storageType: {
                                            enum: ['Garage', 'Carport', 'Drive', 'Street'],
                                        },
                                    },
                                },
                                {
                                    required: ['storageDetails'],
                                    properties: {
                                        storageType: {
                                            enum: ['Other'],
                                        },
                                        storageDetails: {
                                            type: 'string',
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                hasTurbo: {
                    type: 'object',
                    required: ['hasTurboValue'],
                    properties: {
                        hasTurboValue: {
                            type: 'boolean',
                            enum: [true, false],
                        },
                    },
                    dependencies: {
                        hasTurboValue: {
                            oneOf: [
                                {
                                    properties: {
                                        hasTurboValue: {
                                            enum: [true],
                                        },
                                        stepSubheading: { type: 'null' },
                                    },
                                },
                                {
                                    properties: {
                                        hasTurboValue: {
                                            enum: [false],
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                fittings: {
                    type: 'array',
                    uniqueItems: true,
                    items: {
                        type: 'string',
                        enum: ['Alarm', 'Immobilizer'],
                    },
                },
                financingCompanyDetails: {
                    type: 'object',
                    properties: {
                        financingCompany: {
                            type: 'string',
                            enum: financeCompaniesWithPlaceholder,
                            default: 'Please choose a financing company',
                        },
                    },
                    dependencies: {
                        financingCompany: {
                            oneOf: [
                                {
                                    required: ['otherFinancingCompany'],
                                    properties: {
                                        financingCompany: {
                                            enum: ['Please choose a financing company'],
                                        },
                                        otherFinancingCompany: {
                                            type: 'string',
                                        },
                                    },
                                },
                                {
                                    properties: {
                                        financingCompany: {
                                            enum: financeCompanies,
                                        },
                                    },
                                },
                            ],
                        },
                    },
                },
                modificationDetails: {
                    type: 'object',
                    properties: {
                        modificationOverAGrand: {
                            type: 'array',
                            items: {
                                type: 'string',
                                enum: [
                                    'Body kit',
                                    'Engine modifications',
                                    'Custom paint',
                                    'Mag wheels',
                                    'Suspension modifications',
                                    'Other',
                                ],
                            },
                            uniqueItems: true,
                        },
                        modificationOverAGrandNote: {
                            type: 'string',
                        },
                    },
                },
            },
        },
    },
};
